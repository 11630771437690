export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Anything: any;
  DateTime: any;
  JSON: any;
};

export type AddToCartInput = {
  /** Quantity of item to add */
  addend: Scalars['Float'];
  cartId?: Maybe<Scalars['String']>;
  kind: MarketableKind;
  newCart: Scalars['Boolean'];
  productId: Scalars['String'];
  refPage?: Maybe<Scalars['String']>;
};

export type AddToCartPayload = {
  __typename?: 'AddToCartPayload';
  cart?: Maybe<Cart>;
};

export type AddToWishlistInput = {
  marketableId: Scalars['String'];
  marketableKind: MarketableKind;
  wishlistId: Scalars['Int'];
};

export type AddToWishlistPayload = {
  __typename?: 'AddToWishlistPayload';
  wishlist: Wishlist;
};

export enum AdditionalInfoBox {
  Disabled = 'DISABLED',
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type AdditionalInfoBoxSettings = {
  __typename?: 'AdditionalInfoBoxSettings';
  image?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
  type: AdditionalInfoBox;
};

export type Address = DeliveryAddress | PickupAddress;

export type AddressConnection = {
  __typename?: 'AddressConnection';
  nodes: Array<Address>;
  totalCount: Scalars['Int'];
};

export enum AddressKind {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type Answer = {
  __typename?: 'Answer';
  questions?: Maybe<Array<AnsweredQuestions>>;
  reviewedProducts?: Maybe<Array<ReviewedProducts>>;
};

export type AnswerInput = {
  questions?: Maybe<Array<AnsweredQuestionsInput>>;
  reviewedProducts?: Maybe<Array<ReviewedProductsInput>>;
};

export enum AnswerStatus {
  Answered = 'ANSWERED',
  Created = 'CREATED',
  Opened = 'OPENED'
}

export type AnsweredQuestions = {
  __typename?: 'AnsweredQuestions';
  answer: Scalars['String'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
};

export type AnsweredQuestionsInput = {
  answer: Scalars['String'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
};


export type ApplyCouponCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ApplyCouponCouponPayload = {
  __typename?: 'ApplyCouponCouponPayload';
  code: Scalars['String'];
  id: Scalars['String'];
  isFreeDeliveryCoupon: Scalars['Boolean'];
  isProductCoupon: Scalars['Boolean'];
  name: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type ApplyCouponDeliverySettingsInput = {
  centerId?: Maybe<Scalars['String']>;
  coordinates: ApplyCouponCoordinatesInput;
  kind: AddressKind;
};

export type ApplyCouponInput = {
  cartId: Scalars['String'];
  code: Scalars['String'];
  deliverySettings: ApplyCouponDeliverySettingsInput;
  isMobileApp?: Maybe<Scalars['Boolean']>;
  paymentMethodBrand?: Maybe<PaymentMethodBrand>;
};

export type ApplyCouponPayload = {
  __typename?: 'ApplyCouponPayload';
  coupon?: Maybe<ApplyCouponCouponPayload>;
  finalDiscount?: Maybe<Scalars['Float']>;
  /** @deprecated Should be inside of coupon object, will be removed on next app release */
  isProductCoupon?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type AuthenticateUserInput = {
  tokenUrl: Scalars['String'];
};

export type AuthenticateUserPayload = {
  __typename?: 'AuthenticateUserPayload';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type AvailableDatesCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type AvailableDatesInput = {
  centerId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<AvailableDatesCoordinatesInput>;
  maxDates?: Maybe<Scalars['Float']>;
  paymentType: PaymentMethodType;
  untilDate?: Maybe<Scalars['DateTime']>;
};

export type AvailableExpressDeliveryTypesInput = {
  coordinates: CoordinatesInput;
  paymentType: PaymentMethodType;
};

export type AvailablePeriod = {
  __typename?: 'AvailablePeriod';
  /** @deprecated Use field timeRange[1] */
  endHour: Scalars['Int'];
  id: Period;
  name: Scalars['String'];
  /** @deprecated Use field timeRange[0] */
  startHour: Scalars['Int'];
  timeRange: Array<Scalars['Int']>;
  used: Scalars['Float'];
};

export type B2BSale = {
  __typename?: 'B2BSale';
  customer: B2BSaleCustomer;
  distributionCenter: B2BSaleDistributionCenter;
  order: B2BSaleOrder;
  saleId: Scalars['String'];
};

export type B2BSaleAddress = {
  __typename?: 'B2BSaleAddress';
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type B2BSaleBilling = {
  __typename?: 'B2BSaleBilling';
  customPaymentDueDays?: Maybe<Scalars['Int']>;
  deliveryCost: Scalars['Float'];
  total: Scalars['Float'];
};

export type B2BSaleCustomer = {
  __typename?: 'B2BSaleCustomer';
  document: Scalars['String'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type B2BSaleDistributionCenter = {
  __typename?: 'B2BSaleDistributionCenter';
  address: B2BSaleAddress;
  taxId: Scalars['String'];
};

export type B2BSaleOrder = {
  __typename?: 'B2BSaleOrder';
  billing: B2BSaleBilling;
  deliveryAddress: B2BSaleAddress;
  externalOrderNumber?: Maybe<Scalars['String']>;
  invoiceRemarks?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  skipPicking?: Maybe<Scalars['Boolean']>;
  soldItems: Array<B2BSaleSoldItem>;
};

export type B2BSaleSoldItem = {
  __typename?: 'B2BSaleSoldItem';
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
};

export type B2bItem = {
  price?: Maybe<Scalars['Float']>;
  quantity: Scalars['Int'];
  sku: Scalars['String'];
};

export type BagItem = {
  itemId: Scalars['String'];
  kind: MarketableKind;
  quantity: Scalars['Float'];
};

export type Banner = {
  __typename?: 'Banner';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type BoletoPaymentMethod = IPaymentMethod & {
  __typename?: 'BoletoPaymentMethod';
  id?: Maybe<Scalars['String']>;
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type BrandSettings = {
  __typename?: 'BrandSettings';
  brand?: Maybe<Scalars['String']>;
  isLivupBrand: Scalars['Boolean'];
};

export type CkOrder = {
  __typename?: 'CKOrder';
  /** @deprecated Use field orderDiscounts */
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveredAt?: Maybe<Scalars['DateTime']>;
  deliveryAddress: CkOrderDeliveryAddress;
  deliveryFee: Scalars['Int'];
  discounts: Array<Discounts>;
  /** @deprecated Use field orderDiscounts */
  discountsTotal: Scalars['Int'];
  distributionCenterId: Scalars['String'];
  estimatedDelivery: EstimatedDelivery;
  /** @deprecated Use field estimatedDelivery */
  estimatedDeliveryAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<CkOrderItem>;
  itemsTotal: Scalars['Int'];
  orderDiscounts: CkOrderDiscounts;
  orderNumber: Scalars['Int'];
  paymentMethod: CkOrderPaymentMethod;
  status: CkOrderStatus;
  statusLogs: Array<CkOrderStatusLog>;
  survey?: Maybe<OrderSurvey>;
  totalPrice: Scalars['Int'];
  user: CkOrderUser;
};

export type CkOrderConnection = {
  __typename?: 'CKOrderConnection';
  nodes: Array<CkOrder>;
  totalCount: Scalars['Int'];
};

export type CkOrderCoordinates = {
  __typename?: 'CKOrderCoordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CkOrderDeliveryAddress = {
  __typename?: 'CKOrderDeliveryAddress';
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  coordinates: CkOrderCoordinates;
  country?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number: Scalars['String'];
  referencePoint?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CkOrderDiscount = {
  __typename?: 'CKOrderDiscount';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type CkOrderDiscounts = {
  __typename?: 'CKOrderDiscounts';
  coupon?: Maybe<CkOrderDiscount>;
  referralDiscountValue?: Maybe<Scalars['Int']>;
};

export type CkOrderItem = {
  __typename?: 'CKOrderItem';
  name: Scalars['String'];
  quantity: Scalars['Int'];
  storeId?: Maybe<Scalars['String']>;
  subItems: Array<CkOrderSubItem>;
  total: Scalars['Int'];
};

export type CkOrderPaymentMethod = {
  __typename?: 'CKOrderPaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  card?: Maybe<CkOrderPaymentMethodCard>;
  type: PaymentMethodType;
};

export type CkOrderPaymentMethodCard = {
  __typename?: 'CKOrderPaymentMethodCard';
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  lastDigits: Scalars['String'];
  mundipaggCardId: Scalars['String'];
};

export enum CkOrderStatus {
  Delivered = 'DELIVERED',
  Dispatched = 'DISPATCHED',
  Pending = 'PENDING',
  Preparing = 'PREPARING'
}

export type CkOrderStatusLog = {
  __typename?: 'CKOrderStatusLog';
  createdAt: Scalars['DateTime'];
  status: CkOrderStatus;
};

export type CkOrderSubItem = {
  __typename?: 'CKOrderSubItem';
  name: Scalars['String'];
};

export type CkOrderUser = {
  __typename?: 'CKOrderUser';
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ie?: Maybe<Scalars['String']>;
  livupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type CkOrdersPalantirFilter = {
  status_in?: Maybe<Array<CkOrderStatus>>;
};

export type CkStoresDeliveryInfo = {
  __typename?: 'CKStoresDeliveryInfo';
  deliveryEstimatedTime: EstimatedDeliveryTime;
  deliveryFee: Scalars['Int'];
  storeId: Scalars['String'];
};

export type CkUpdateOrderSurveyAnswerInput = {
  answer: Scalars['String'];
  questionName: OrderSurveyQuestionName;
};

export type CkUpdateOrderSurveyInput = {
  answers: Array<CkUpdateOrderSurveyAnswerInput>;
  orderId: Scalars['String'];
};

export type CkUpdateOrderSurveyResponse = {
  __typename?: 'CKUpdateOrderSurveyResponse';
  order: CkOrder;
};

export type CmsAccordionItem = {
  __typename?: 'CMSAccordionItem';
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsColor = {
  __typename?: 'CMSColor';
  background?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerAccordion = {
  __typename?: 'CMSComponentContainerAccordion';
  accordionItem: Array<CmsAccordionItem>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerBanner = {
  __typename?: 'CMSComponentContainerBanner';
  description?: Maybe<Scalars['String']>;
  distributionCenters?: Maybe<Array<CmsDistributionCenter>>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  imageDesktop?: Maybe<CmsUploadFile>;
  imageMobile?: Maybe<CmsUploadFile>;
  redirectUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  userProfiles?: Maybe<Array<CmsUserProfile>>;
};

export type CmsComponentContainerBannerBreadcrumb = {
  __typename?: 'CMSComponentContainerBannerBreadcrumb';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<CmsUploadFile>;
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerButton = {
  __typename?: 'CMSComponentContainerButton';
  block: Scalars['Boolean'];
  id: Scalars['String'];
  newTab: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  size?: Maybe<IcmsEnumComponentcontainerbuttonSize>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<IcmsEnumComponentcontainerbuttonType>;
};

export type CmsComponentContainerCallToAction = {
  __typename?: 'CMSComponentContainerCallToAction';
  buttonText?: Maybe<Scalars['String']>;
  color?: Maybe<CmsColor>;
  id: Scalars['String'];
  image?: Maybe<CmsUploadFile>;
  link?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerCustomHtml = {
  __typename?: 'CMSComponentContainerCustomHtml';
  css?: Maybe<Scalars['String']>;
  head?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  javascript?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerDeliveryAreaCard = {
  __typename?: 'CMSComponentContainerDeliveryAreaCard';
  deliveryMapLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mainCity?: Maybe<Scalars['String']>;
  minorCities?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  takeoffMapLink?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerDisplay = {
  __typename?: 'CMSComponentContainerDisplay';
  cardSize?: Maybe<IcmsEnumComponentcontainerdisplayCardsize>;
  display?: Maybe<Scalars['String']>;
  home?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAnchorable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Scalars['JSON']>>;
  redirectUrl?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerHeader = {
  __typename?: 'CMSComponentContainerHeader';
  color?: Maybe<CmsColor>;
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerHighlightStory = {
  __typename?: 'CMSComponentContainerHighlightStory';
  destinationUrl: Scalars['String'];
  distributionCenters?: Maybe<Array<CmsDistributionCenter>>;
  id: Scalars['String'];
  image?: Maybe<CmsUploadFile>;
  title: Scalars['String'];
  userProfiles?: Maybe<Array<CmsUserProfile>>;
};

export type CmsComponentContainerImage = {
  __typename?: 'CMSComponentContainerImage';
  borderRadius?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<CmsUploadFile>;
  isVertical?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerNavigationAnchors = {
  __typename?: 'CMSComponentContainerNavigationAnchors';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CmsComponentContainerProducerAreaCards = {
  __typename?: 'CMSComponentContainerProducerAreaCards';
  cards?: Maybe<Array<CmsComponentContainerProducerCard>>;
  id: Scalars['String'];
};

export type CmsComponentContainerProducerCard = {
  __typename?: 'CMSComponentContainerProducerCard';
  id: Scalars['String'];
  image?: Maybe<CmsUploadFile>;
  name: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerQuote = {
  __typename?: 'CMSComponentContainerQuote';
  author?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  quote?: Maybe<Scalars['String']>;
};

export type CmsComponentContainerText = {
  __typename?: 'CMSComponentContainerText';
  anchorText?: Maybe<Scalars['String']>;
  customText: Scalars['String'];
  id: Scalars['String'];
  isAnchorable?: Maybe<Scalars['Boolean']>;
};

export type CmsComponentContainerVideo = {
  __typename?: 'CMSComponentContainerVideo';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CmsComponentDeliveryAreaCards = {
  __typename?: 'CMSComponentDeliveryAreaCards';
  cards?: Maybe<Array<CmsComponentContainerDeliveryAreaCard>>;
  id: Scalars['String'];
};

export type CmsComponentHighlightStoryHighlightStories = {
  __typename?: 'CMSComponentHighlightStoryHighlightStories';
  highlights?: Maybe<Array<CmsComponentContainerHighlightStory>>;
  id: Scalars['String'];
};

export type CmsContainer = {
  __typename?: 'CMSContainer';
  components?: Maybe<Array<ContainerComponentsDynamicZoneUnion>>;
  id: Scalars['ID'];
  layout: IcmsEnumContainerLayout;
  size: IcmsEnumContainerSize;
  slider: Scalars['Boolean'];
};

export type CmsDistributionCenter = {
  __typename?: 'CMSDistributionCenter';
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CmsPage = {
  __typename?: 'CMSPage';
  containers?: Maybe<Array<CmsContainer>>;
  id: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CmsUploadFile = {
  __typename?: 'CMSUploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  ext?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type CmsUserProfile = {
  __typename?: 'CMSUserProfile';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CalcSaleLoyaltyCreditsInput = {
  cartId?: Maybe<Scalars['String']>;
  cartTotal: Scalars['Float'];
  items?: Maybe<Array<MissionCartItem>>;
  saleChannel?: Maybe<SaleChannel>;
  shippingCost: Scalars['Float'];
  subtotal?: Maybe<Scalars['Float']>;
};

export type CalculateSaleLoyaltyCreditsPayload = {
  __typename?: 'CalculateSaleLoyaltyCreditsPayload';
  daysToExpire?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export enum CardLayout {
  BigText = 'BIG_TEXT',
  Default = 'DEFAULT',
  Full = 'FULL',
  HorizontalLarge = 'HORIZONTAL_LARGE',
  HorizontalList = 'HORIZONTAL_LIST',
  HorizontalMedium = 'HORIZONTAL_MEDIUM',
  HorizontalSlim = 'HORIZONTAL_SLIM',
  Small = 'SMALL',
  Square = 'SQUARE',
  VerticalLarge = 'VERTICAL_LARGE',
  Wide = 'WIDE'
}

export enum CardTagType {
  Disabled = 'DISABLED',
  New = 'NEW'
}

export enum CardType {
  Accordion = 'ACCORDION',
  Banner = 'BANNER',
  Default = 'DEFAULT',
  InfoCard = 'INFO_CARD',
  ProductCard = 'PRODUCT_CARD'
}

export type Cart = INode & {
  __typename?: 'Cart';
  billing?: Maybe<CartBilling>;
  createdAt: Scalars['DateTime'];
  deliverySettings?: Maybe<CartDeliverySettings>;
  id: Scalars['ID'];
  items: CartItemConection;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  marketingEmailsCount: Scalars['Float'];
  productTypes: Array<ProductType>;
  status?: Maybe<CartStatus>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<Scalars['String']>;
};

export type CartBilling = {
  __typename?: 'CartBilling';
  coupon?: Maybe<Coupon>;
  delivery?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<CartBillingPaymentMethod>;
  referralDiscount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type CartBillingPaymentMethod = CartBillingPaymentMethodBoleto | CartBillingPaymentMethodOffline | CartBillingPaymentMethodOnline | CartBillingPaymentMethodPix;

export type CartBillingPaymentMethodBoleto = {
  __typename?: 'CartBillingPaymentMethodBoleto';
  boletoLine?: Maybe<Scalars['String']>;
  boletoPdf?: Maybe<Scalars['String']>;
  boletoUrl?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type CartBillingPaymentMethodOffline = {
  __typename?: 'CartBillingPaymentMethodOffline';
  brand: PaymentMethodBrand;
  type: PaymentMethodType;
};

export type CartBillingPaymentMethodOnline = {
  __typename?: 'CartBillingPaymentMethodOnline';
  acquirer?: Maybe<Scalars['String']>;
  brand: PaymentMethodBrand;
  gatewayId?: Maybe<Scalars['String']>;
  nsu?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type CartBillingPaymentMethodPix = {
  __typename?: 'CartBillingPaymentMethodPix';
  expiresAt?: Maybe<Scalars['DateTime']>;
  nsu?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type CartDeliveryChosenPeriod = {
  __typename?: 'CartDeliveryChosenPeriod';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use field timeRange[0] */
  startHour?: Maybe<Scalars['Float']>;
  timeRange: Array<Scalars['Float']>;
};

export type CartDeliverySettings = {
  __typename?: 'CartDeliverySettings';
  address: CartDeliverySettingsAddress;
  authorize?: Maybe<Scalars['Boolean']>;
  canBeRescheduledUntil?: Maybe<Scalars['String']>;
  chosenDate?: Maybe<Scalars['DateTime']>;
  chosenDateString?: Maybe<Scalars['String']>;
  chosenPeriod?: Maybe<CartDeliveryChosenPeriod>;
  deliveryArea?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  deliveryModifier?: Maybe<DeliveryModifier>;
  deliveryWindow?: Maybe<DeliveryWindow>;
  distributionCenter?: Maybe<Scalars['String']>;
  kind?: Maybe<AddressKind>;
};

export type CartDeliverySettingsAddress = {
  __typename?: 'CartDeliverySettingsAddress';
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  coordinates: Coordinates;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  classification: MarketableClassification;
  id: Scalars['ID'];
  isGiftCoupon: Scalars['Boolean'];
  item: IMarketable;
  kind: MarketableKind;
  quantity: Scalars['Float'];
  refPage?: Maybe<Scalars['String']>;
};

export type CartItemConection = {
  __typename?: 'CartItemConection';
  nodes: Array<CartItem>;
  totalCount: Scalars['Int'];
};

export enum CartStatus {
  Abandoned = 'ABANDONED',
  Active = 'ACTIVE',
  Processing = 'PROCESSING',
  Sold = 'SOLD'
}

export type CartSyncInput = {
  cartId?: Maybe<Scalars['String']>;
  forceSync?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<CartSyncItemInput>>;
  /** UTC date */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartSyncItemInput = {
  classification?: Maybe<MarketableClassification>;
  kind: MarketableKind;
  productId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type CartSyncPayload = {
  __typename?: 'CartSyncPayload';
  cart?: Maybe<Cart>;
};

export type CatalogueCategory = {
  __typename?: 'CatalogueCategory';
  kind: MarketableKind;
  level?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
};

export type CellphoneValidation = {
  __typename?: 'CellphoneValidation';
  editedBy: Scalars['String'];
  editionSource: CellphoneValidationEditionSourceEnum;
  isCellphoneValidationExpired?: Maybe<Scalars['Boolean']>;
  status: CellphoneValidationStatusEnum;
  tokenValidatedPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  validatedAccountEmail?: Maybe<Scalars['String']>;
};

export enum CellphoneValidationEditionSourceEnum {
  Internal = 'INTERNAL',
  User = 'USER'
}

export type CellphoneValidationSendCodeObjectPayload = {
  __typename?: 'CellphoneValidationSendCodeObjectPayload';
  enforceSecureValidation?: Maybe<Scalars['Boolean']>;
  expireInMinutes?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type CellphoneValidationSendCodePayload = {
  __typename?: 'CellphoneValidationSendCodePayload';
  message: Scalars['String'];
  object: CellphoneValidationSendCodeObjectPayload;
  success: Scalars['Boolean'];
};

export enum CellphoneValidationStatusEnum {
  Inactive = 'INACTIVE',
  NotValidated = 'NOT_VALIDATED',
  TokenValidated = 'TOKEN_VALIDATED',
  Validated = 'VALIDATED'
}

export type CellphoneValidationUpdate = {
  __typename?: 'CellphoneValidationUpdate';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CellphoneValidationValidateAccountPayload = {
  __typename?: 'CellphoneValidationValidateAccountPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CellphoneValidationValidateCodeObjectPayload = {
  __typename?: 'CellphoneValidationValidateCodeObjectPayload';
  phoneNumber?: Maybe<Scalars['String']>;
  tokenCode?: Maybe<Scalars['String']>;
};

export type CellphoneValidationValidateCodePayload = {
  __typename?: 'CellphoneValidationValidateCodePayload';
  emails?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  object?: Maybe<CellphoneValidationValidateCodeObjectPayload>;
  success: Scalars['Boolean'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  success: Scalars['Boolean'];
};

export type ChangePasswordWithTokenInput = {
  newPassword: Scalars['String'];
  tokenUrl: Scalars['String'];
};

export type ClaimCartInput = {
  cartId: Scalars['String'];
};

export type ClaimCartPayload = {
  __typename?: 'ClaimCartPayload';
  cart?: Maybe<Cart>;
};

export type ClearRemovedProductPayload = {
  __typename?: 'ClearRemovedProductPayload';
  kit: Kit;
};

export type ClearRemovedProductsInput = {
  kitId: Scalars['String'];
};

export type ClosestCartMissionToAccomplishInput = {
  distributionCenterId: Scalars['String'];
  items: Array<MissionCartItem>;
  subtotal: Scalars['Float'];
};

export type ClosestCartMissionToAccomplishPayload = {
  __typename?: 'ClosestCartMissionToAccomplishPayload';
  message: Scalars['String'];
  wasAccomplished: Scalars['Boolean'];
};

export type Component = INode & {
  __typename?: 'Component';
  card: DynamicPageCard;
  components?: Maybe<Array<Component>>;
  createdAt: Scalars['DateTime'];
  customComponents?: Maybe<CustomComponents>;
  defaultCard: DynamicPageCard;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  product?: Maybe<IMarketable>;
  route?: Maybe<ComponentRoute>;
  shouldHideWithoutDc?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ComponentCardArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};


export type ComponentComponentsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  trackerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type ComponentDefaultCardArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};

export type ComponentRoute = {
  __typename?: 'ComponentRoute';
  params: Scalars['Anything'];
  routeName: Scalars['String'];
};

export enum ComponentsFilter {
  BestRated = 'BEST_RATED',
  BestSellers = 'BEST_SELLERS',
  Default = 'DEFAULT',
  Favorites = 'FAVORITES',
  New = 'NEW',
  Rebuy = 'REBUY',
  Recommended = 'RECOMMENDED'
}

export enum ConsumableKind {
  BabyFood = 'BABY_FOOD',
  Basket = 'BASKET',
  EasterEggs = 'EASTER_EGGS',
  Frozen = 'FROZEN',
  GreenGrocer = 'GREEN_GROCER',
  Refrigerated = 'REFRIGERATED',
  Snacks = 'SNACKS'
}

export type ContainerAction = {
  __typename?: 'ContainerAction';
  buttonTitle: Scalars['String'];
  imageAlt?: Maybe<Scalars['String']>;
  imageHint?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  route: ComponentRoute;
};

export type ContainerComponentsDynamicZoneUnion = CmsComponentContainerAccordion | CmsComponentContainerBanner | CmsComponentContainerBannerBreadcrumb | CmsComponentContainerButton | CmsComponentContainerCallToAction | CmsComponentContainerCustomHtml | CmsComponentContainerDeliveryAreaCard | CmsComponentContainerDisplay | CmsComponentContainerHeader | CmsComponentContainerImage | CmsComponentContainerNavigationAnchors | CmsComponentContainerProducerAreaCards | CmsComponentContainerProducerCard | CmsComponentContainerQuote | CmsComponentContainerText | CmsComponentContainerVideo | CmsComponentDeliveryAreaCards | CmsComponentHighlightStoryHighlightStories;

export enum ContainerDisplayType {
  Grid = 'GRID',
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL'
}

export type CookingTime = {
  __typename?: 'CookingTime';
  minutes: Scalars['Float'];
  seconds: Scalars['Float'];
};

export type CookingTimeItem = {
  __typename?: 'CookingTimeItem';
  range: CookingTimeRange;
  type: Scalars['String'];
};

export type CookingTimeRange = {
  __typename?: 'CookingTimeRange';
  max?: Maybe<CookingTime>;
  min?: Maybe<CookingTime>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Coupon = INode & {
  __typename?: 'Coupon';
  codes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  kit?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Float']>;
  meal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product?: Maybe<Scalars['String']>;
  type: CouponDiscountType;
  updatedAt: Scalars['DateTime'];
  usageCount?: Maybe<Scalars['Float']>;
  usedBy: CouponUsageConnection;
  value: Scalars['Float'];
};

export enum CouponDiscountType {
  Absolute = 'ABSOLUTE',
  Product = 'PRODUCT',
  Relative = 'RELATIVE'
}

export type CouponEarlyValidationInput = {
  bag?: Maybe<Array<BagItem>>;
  cartId: Scalars['String'];
  code: Scalars['String'];
  deliverySettings?: Maybe<ApplyCouponDeliverySettingsInput>;
  isCheckoutReview?: Maybe<Scalars['Boolean']>;
  isMobileApp?: Maybe<Scalars['Boolean']>;
  paymentMethodBrand?: Maybe<PaymentMethodBrand>;
  subtotal?: Maybe<Scalars['Float']>;
};

export type CouponEarlyValidationPayload = {
  __typename?: 'CouponEarlyValidationPayload';
  coupon?: Maybe<ApplyCouponCouponPayload>;
  finalDiscount?: Maybe<Scalars['Float']>;
  message: Scalars['String'];
  pendingValidation?: Maybe<Scalars['Boolean']>;
  valid: Scalars['Boolean'];
};

export type CouponUsage = {
  __typename?: 'CouponUsage';
  date?: Maybe<Scalars['DateTime']>;
  user: CouponUsageUser;
  value?: Maybe<Scalars['Int']>;
};

export type CouponUsageConnection = {
  __typename?: 'CouponUsageConnection';
  nodes: Array<CouponUsage>;
  totalCount: Scalars['Int'];
};

export type CouponUsageUser = {
  __typename?: 'CouponUsageUser';
  name: Scalars['String'];
};

export type CreateB2bSaleInput = {
  additionalInfo?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  discount?: Maybe<Scalars['Float']>;
  distributionCenter: Scalars['String'];
  email: Scalars['String'];
  externalOrderNumber?: Maybe<Scalars['String']>;
  items: Array<B2bItem>;
  period: Period;
  skipOrderPicking?: Maybe<Scalars['Boolean']>;
};

export type CreateWishlistInput = {
  name: Scalars['String'];
};

export type CreateWishlistPayload = {
  __typename?: 'CreateWishlistPayload';
  wishlist: Wishlist;
};

export type CreditCardPaymentMethod = IPaymentMethod & {
  __typename?: 'CreditCardPaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  cardId: Scalars['String'];
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isWalletGlobal?: Maybe<Scalars['Boolean']>;
  lastDigits: Scalars['String'];
  lastUsedAt: Scalars['DateTime'];
  paypalToken?: Maybe<Scalars['String']>;
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type CustomComponents = {
  __typename?: 'CustomComponents';
  cardType: CardType;
  /** @deprecated Not usable anymore */
  componentsFilter?: Maybe<ComponentsFilter>;
  itemLimit?: Maybe<Scalars['Float']>;
  layoutType: CardLayout;
};

export enum DcSellingStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  SoldOut = 'SOLD_OUT'
}


export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city: Scalars['String'];
  ckStoresDeliveryInfo?: Maybe<Array<CkStoresDeliveryInfo>>;
  complement?: Maybe<Scalars['String']>;
  coordinates: Coordinates;
  country?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  fullAddress: Scalars['String'];
  lastUsedAt: Scalars['DateTime'];
  nearestDistributionCenter?: Maybe<DistributionCenter>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  /** @deprecated Use `zipCode` instead */
  postalCode?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};


export type DeliveryAddressDeliveryCostArgs = {
  saleChannel?: Maybe<SaleChannel>;
};


export type DeliveryAddressNearestDistributionCenterArgs = {
  saleChannel?: Maybe<SaleChannel>;
};

export enum DeliveryModifier {
  Economic = 'ECONOMIC',
  Express = 'EXPRESS',
  Scheduled = 'SCHEDULED'
}

export enum DeliveryStatus {
  AtClient = 'AT_CLIENT',
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  FailedToDeliver = 'FAILED_TO_DELIVER',
  GoingToClient = 'GOING_TO_CLIENT',
  InTransit = 'IN_TRANSIT',
  NotShipped = 'NOT_SHIPPED',
  Returned = 'RETURNED',
  Shipped = 'SHIPPED'
}

export type DeliveryWindow = {
  __typename?: 'DeliveryWindow';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type Discounts = {
  __typename?: 'Discounts';
  total: Scalars['Int'];
  type: Scalars['String'];
};

export type Display = {
  __typename?: 'Display';
  classification: MarketableClassification;
  id: Scalars['ID'];
  isSubcategory?: Maybe<Scalars['Boolean']>;
  items: Array<IMarketable>;
  kind: MarketableKind;
  marketableItems: Array<IMarketable>;
  marketableItemsOptimized: Array<IMarketable>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type DisplayItemsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};


export type DisplayMarketableItemsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};


export type DisplayMarketableItemsOptimizedArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};

export type DisplayConnection = {
  __typename?: 'DisplayConnection';
  nodes: Array<Display>;
  totalCount: Scalars['Int'];
};

export type DistributionCenter = INode & {
  __typename?: 'DistributionCenter';
  address: DistributionCenterAddress;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isPickupDisabled: Scalars['Boolean'];
  isSelling: Scalars['Boolean'];
  name: Scalars['String'];
  paypalMerchantId?: Maybe<Scalars['String']>;
  saleChannels: Array<SaleChannel>;
  updatedAt: Scalars['DateTime'];
};

export type DistributionCenterAddress = {
  __typename?: 'DistributionCenterAddress';
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  coordinates: Coordinates;
  country?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  /** @deprecated Use `zipCode` instead */
  zip?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type DistributionCenterConnection = {
  __typename?: 'DistributionCenterConnection';
  nodes: Array<DistributionCenter>;
  totalCount: Scalars['Int'];
};

export type DistributionCentersSellingStatus = {
  __typename?: 'DistributionCentersSellingStatus';
  centerId: Scalars['String'];
  sellingStatus: Scalars['String'];
};

export type DynamicContainer = INode & {
  __typename?: 'DynamicContainer';
  action?: Maybe<ContainerAction>;
  components: Array<Component>;
  componentsStyles?: Maybe<DynamicStyles>;
  createdAt: Scalars['DateTime'];
  customComponents: CustomComponents;
  displayType: ContainerDisplayType;
  id: Scalars['ID'];
  shouldHideWithoutDc?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type DynamicContainerComponentsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  trackerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DynamicDisplay = {
  __typename?: 'DynamicDisplay';
  items: Array<IMarketable>;
};


export type DynamicDisplayItemsArgs = {
  dynamicDisplayInput?: Maybe<DynamicDisplayInput>;
  filteredItems?: Maybe<Array<Scalars['String']>>;
};

export type DynamicDisplayInput = {
  distributionCenterId?: Maybe<Scalars['String']>;
  itemLimit?: Maybe<Scalars['Float']>;
  listName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  pageUrls?: Maybe<Array<Scalars['String']>>;
  trackerUserId?: Maybe<Scalars['String']>;
};

export type DynamicPage = INode & {
  __typename?: 'DynamicPage';
  containers?: Maybe<Array<DynamicContainer>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  pageName: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type DynamicPageContainersArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};

export type DynamicPageCard = {
  __typename?: 'DynamicPageCard';
  cardType: CardType;
  imageAlt?: Maybe<Scalars['String']>;
  imageHint?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  layoutType: CardLayout;
  preTitle?: Maybe<Scalars['String']>;
  styles?: Maybe<DynamicStyles>;
  subtitle?: Maybe<Scalars['String']>;
  tag?: Maybe<DynamicPageCardTag>;
  title?: Maybe<Scalars['String']>;
};

export type DynamicPageCardTag = {
  __typename?: 'DynamicPageCardTag';
  text: Scalars['String'];
  type: CardTagType;
};

export type DynamicPageController = INode & {
  __typename?: 'DynamicPageController';
  createdAt: Scalars['DateTime'];
  headerTitle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pageControllerName: Scalars['String'];
  pageWrappers?: Maybe<Array<DynamicPageWrapper>>;
  updatedAt: Scalars['DateTime'];
};

export type DynamicPageWrapper = {
  __typename?: 'DynamicPageWrapper';
  icon?: Maybe<Scalars['String']>;
  page: DynamicPage;
  selectedIcon?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DynamicStyles = {
  __typename?: 'DynamicStyles';
  backgroundColor?: Maybe<Scalars['String']>;
  titleColor?: Maybe<Scalars['String']>;
};

export type EditAddressInput = {
  newComplement?: Maybe<Scalars['String']>;
  newNumber?: Maybe<Scalars['String']>;
  oldComplement?: Maybe<Scalars['String']>;
  oldNumber?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type EditAddressPayload = {
  __typename?: 'EditAddressPayload';
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type EmailSignIn = {
  email: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  skipRecaptchaToken?: Maybe<Scalars['String']>;
};

export type EmailSignUp = {
  cpf?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  rawPassword: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  skipRecaptchaToken?: Maybe<Scalars['String']>;
};

export type EstimateShipping = {
  __typename?: 'EstimateShipping';
  deliveryCost?: Maybe<Scalars['Float']>;
  distributionCenter?: Maybe<DistributionCenter>;
  valid: Scalars['Boolean'];
};

export type EstimateShippingCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type EstimateShippingInput = {
  coordinates: EstimateShippingCoordinatesInput;
  saleChannel?: Maybe<SaleChannel>;
};

export type EstimatedDelivery = {
  __typename?: 'EstimatedDelivery';
  maxDate: Scalars['DateTime'];
  minDate: Scalars['DateTime'];
};

export type EstimatedDeliveryTime = {
  __typename?: 'EstimatedDeliveryTime';
  maxMinutes: Scalars['Int'];
  minMinutes: Scalars['Int'];
};

export type ExpressDeliveryType = {
  __typename?: 'ExpressDeliveryType';
  fee: Scalars['Int'];
  id: Scalars['String'];
  maxMinutes: Scalars['Int'];
  /** @deprecated Express deliveries now use KML regions to check if the user is in the range */
  maxRadius: Scalars['Float'];
  name: Scalars['String'];
};

export type ExpressDeliveryTypeConnection = {
  __typename?: 'ExpressDeliveryTypeConnection';
  nodes: Array<ExpressDeliveryType>;
  totalCount: Scalars['Int'];
};

export type ExternalMundipaggCardInfoPayload = {
  __typename?: 'ExternalMundipaggCardInfoPayload';
  brand: Scalars['String'];
  cardId: Scalars['String'];
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  lastDigits: Scalars['String'];
  status: MundipaggCardStatusEnum;
  type: MundipaggCardTypeEnum;
};

export type ExternalMundipaggCardsPayload = {
  __typename?: 'ExternalMundipaggCardsPayload';
  cards: Array<ExternalMundipaggCardInfoPayload>;
  total: Scalars['Float'];
};

export type FacebookSignIn = {
  email?: Maybe<Scalars['String']>;
  limitedLoginToken?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export type FirstOfferReplacementItem = {
  __typename?: 'FirstOfferReplacementItem';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  priceFrom?: Maybe<Scalars['Float']>;
  sku: Scalars['String'];
  totalMealsCount?: Maybe<Scalars['Float']>;
};

export type FirstOfferReplacementSettings = {
  __typename?: 'FirstOfferReplacementSettings';
  firstBuy: FirstOfferReplacementItem;
  rebuy: FirstOfferReplacementItem;
};

export type Fiscal = {
  __typename?: 'Fiscal';
  barcode?: Maybe<Scalars['String']>;
  cfop: Scalars['String'];
  ncm: Scalars['Float'];
};

export type GoogleSignIn = {
  authorizationCode?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export type HighlighStoryContent = {
  __typename?: 'HighlighStoryContent';
  destinationUrl: Scalars['String'];
};

export type HighlightStories = {
  __typename?: 'HighlightStories';
  count: Scalars['Float'];
  stories?: Maybe<Array<HighlightStory>>;
};

export type HighlightStory = {
  __typename?: 'HighlightStory';
  content: HighlighStoryContent;
  imageUrl: Scalars['String'];
  listPosition: Scalars['Float'];
  onlyOnDistributionCenters?: Maybe<Array<Scalars['String']>>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export enum IcmsEnumComponentcontainerbuttonSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum IcmsEnumComponentcontainerbuttonType {
  Default = 'DEFAULT',
  Link = 'LINK',
  Outlined = 'OUTLINED'
}

export enum IcmsEnumComponentcontainerdisplayCardsize {
  Default = 'DEFAULT',
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum IcmsEnumContainerLayout {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL'
}

export enum IcmsEnumContainerSize {
  Content = 'CONTENT',
  Fullpage = 'FULLPAGE',
  Fullscreen = 'FULLSCREEN'
}

export enum IcmsUserProfiles {
  FirstBuy = 'FIRST_BUY',
  Rebuy = 'REBUY'
}

export type IConsumable = {
  additionalInfoBox?: Maybe<AdditionalInfoBoxSettings>;
  allergenics?: Maybe<Scalars['String']>;
  imagesUrl?: Maybe<Array<Scalars['String']>>;
  ingredients?: Maybe<Scalars['String']>;
  mayContain?: Maybe<Scalars['String']>;
  nutritionFacts?: Maybe<NutritionFact>;
  nutritionalFacts: Array<NutritionalFact>;
  priorityMeasure?: Maybe<PriorityMeasure>;
  restrictions: Array<MarketableRestrictions>;
  tags: Array<Scalars['String']>;
  totalUnits?: Maybe<Scalars['Int']>;
  type: ConsumableKind;
  weight?: Maybe<Scalars['Int']>;
};

export type IMarketable = {
  catalogueCategories: Array<CatalogueCategory>;
  categories: Array<Scalars['String']>;
  classification: MarketableClassification;
  description: Scalars['String'];
  directions?: Maybe<Scalars['String']>;
  distributionCentersNotSelling: Array<Scalars['String']>;
  distributionCentersSellingStatus: Array<DistributionCentersSellingStatus>;
  fiscal: Fiscal;
  gender: MarketableGender;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isActive: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isLowCost: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  itemTags: ItemTagConnection;
  kind: MarketableKind;
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceFrom?: Maybe<Scalars['Float']>;
  promotionalSettings: PromotionalSettings;
  releaseDateString?: Maybe<Scalars['String']>;
  sellingStatus: SellingStatus;
  seo: ProductSeo;
  sku: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type INode = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type IPaymentMethod = {
  id?: Maybe<Scalars['String']>;
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type ISearchItem = {
  distributionCentersNotSelling: Array<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type ItemTag = {
  __typename?: 'ItemTag';
  colorPalette: ItemTagsColors;
  content: ItemTagsContent;
};

export type ItemTagConnection = {
  __typename?: 'ItemTagConnection';
  nodes: Array<ItemTag>;
  totalCount: Scalars['Int'];
};

export enum ItemTagsColors {
  Default = 'DEFAULT',
  New = 'NEW',
  Promotion = 'PROMOTION'
}

export enum ItemTagsContent {
  AllFruit = 'ALL_FRUIT',
  ChefsChoice = 'CHEFS_CHOICE',
  ColdPressed = 'COLD_PRESSED',
  ContainsOrganic = 'CONTAINS_ORGANIC',
  Eggs = 'EGGS',
  Gluten = 'GLUTEN',
  IsBestSeller = 'IS_BEST_SELLER',
  IsNew = 'IS_NEW',
  Lactose = 'LACTOSE',
  LessThan_500Calories = 'LESS_THAN_500_CALORIES',
  LowCarb = 'LOW_CARB',
  NaturalSweeteners = 'NATURAL_SWEETENERS',
  NoSugar = 'NO_SUGAR',
  Nuts = 'NUTS',
  OnlyVegetables = 'ONLY_VEGETABLES',
  OrganicProduct = 'ORGANIC_PRODUCT',
  ProductWithOrganicIngredients = 'PRODUCT_WITH_ORGANIC_INGREDIENTS',
  Protein_15G = 'PROTEIN_15G',
  Protein_30G = 'PROTEIN_30G',
  Refrigerated = 'REFRIGERATED',
  SustainableBeef = 'SUSTAINABLE_BEEF',
  SustainableChicken = 'SUSTAINABLE_CHICKEN',
  SustainableFish = 'SUSTAINABLE_FISH',
  SweetenedWithFruit = 'SWEETENED_WITH_FRUIT',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
  WellRated = 'WELL_RATED'
}


export type Kit = IMarketable & INode & {
  __typename?: 'Kit';
  catalogueCategories: Array<CatalogueCategory>;
  categories: Array<Scalars['String']>;
  classification: MarketableClassification;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  days: Array<KitDay>;
  description: Scalars['String'];
  directions?: Maybe<Scalars['String']>;
  distributionCentersNotSelling: Array<Scalars['String']>;
  distributionCentersSellingStatus: Array<DistributionCentersSellingStatus>;
  firstOfferReplacement?: Maybe<FirstOfferReplacementSettings>;
  fiscal: Fiscal;
  gender: MarketableGender;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  imagesUrl: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isLowCost: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  itemTags: ItemTagConnection;
  kind: MarketableKind;
  meanPricePerDay: Scalars['Float'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceFrom?: Maybe<Scalars['Float']>;
  pricePerDay: Scalars['Float'];
  promotionalSettings: PromotionalSettings;
  releaseDateString?: Maybe<Scalars['String']>;
  removedProducts?: Maybe<Array<RemovedProducts>>;
  sellingStatus: SellingStatus;
  seo: ProductSeo;
  sku: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  totalMealsCount?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Int']>;
};


export type KitIsAvailableArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};


export type KitItemTagsArgs = {
  maxTags?: Maybe<Scalars['Float']>;
};

export type KitDay = {
  __typename?: 'KitDay';
  dayNumber: Scalars['Float'];
  periods: Array<KitDayPeriod>;
  totalKcal: Scalars['Float'];
};

export type KitDayPeriod = {
  __typename?: 'KitDayPeriod';
  name: KitDayPeriodName;
  products: Array<Product>;
  totalKcal: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalWeight: Scalars['Float'];
};

export enum KitDayPeriodName {
  Breakfast = 'BREAKFAST',
  Dinner = 'DINNER',
  Lunch = 'LUNCH',
  Midafternoon = 'MIDAFTERNOON',
  Midmorning = 'MIDMORNING',
  Other = 'OTHER',
  Supper = 'SUPPER'
}

export type LojinhaSessionPayload = {
  __typename?: 'LojinhaSessionPayload';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type LoyaltyStatementPayload = {
  __typename?: 'LoyaltyStatementPayload';
  available: Scalars['Float'];
  credits: Array<StatementCredit>;
  pending: Scalars['Float'];
};

export type LoyaltyTier = {
  __typename?: 'LoyaltyTier';
  ckRewardPercentage: Scalars['Float'];
  daysToExpirate: Scalars['Float'];
  firstMinimumEcommercePurchase: Scalars['Float'];
  firstRewardPercentage: Scalars['Float'];
  id: Scalars['ID'];
  minimumEcommercePurchase: Scalars['Float'];
  name: Scalars['String'];
  rewardPercentage: Scalars['Float'];
};

export type MakePaymentAddressInput = {
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  coordinates: MakePaymentCoordinatesInput;
  country: Scalars['String'];
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type MakePaymentCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type MakePaymentDeliverySettingsInput = {
  address: MakePaymentAddressInput;
  centerId?: Maybe<Scalars['String']>;
  deliveryModifier?: Maybe<DeliveryModifier>;
  doormanAllowedToReceive?: Maybe<Scalars['Boolean']>;
  expressDeliveryTypeId?: Maybe<Scalars['String']>;
  kind: AddressKind;
  period?: Maybe<MakePaymentPeriodInput>;
};

export type MakePaymentInput = {
  cartId: Scalars['String'];
  couponCode?: Maybe<Scalars['String']>;
  deliverySettings: MakePaymentDeliverySettingsInput;
  isMobileApp?: Maybe<Scalars['Boolean']>;
  paymentMethod: MakePaymentPaymentMethodInput;
  platform?: Maybe<MakePaymentPlatformTypes>;
  user?: Maybe<MakePaymentUserInput>;
};

export type MakePaymentPayload = {
  __typename?: 'MakePaymentPayload';
  sale: Sale;
  valid: Scalars['Boolean'];
};

export type MakePaymentPaymentMethodCardInput = {
  brand: PaymentMethodBrand;
  cardId: Scalars['String'];
  cvv?: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  holderDocument: Scalars['String'];
  holderName: Scalars['String'];
  lastDigits: Scalars['String'];
  type: PaymentMethodType;
};

export type MakePaymentPaymentMethodInput = {
  brand?: Maybe<PaymentMethodBrand>;
  card?: Maybe<MakePaymentPaymentMethodCardInput>;
  id?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type MakePaymentPeriodInput = {
  date: Scalars['String'];
  timeId: Scalars['String'];
};

export enum MakePaymentPlatformTypes {
  Native = 'NATIVE',
  Web = 'WEB',
  WebDesktop = 'WEB_DESKTOP',
  WebMobile = 'WEB_MOBILE'
}

export type MakePaymentUserInput = {
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MarketVoucherOfflinePaymentMethod = IPaymentMethod & {
  __typename?: 'MarketVoucherOfflinePaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  id?: Maybe<Scalars['String']>;
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type MarketVoucherOnlinePaymentMethod = IPaymentMethod & {
  __typename?: 'MarketVoucherOnlinePaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  cardId: Scalars['String'];
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastDigits: Scalars['String'];
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export enum MarketableClassification {
  Kit = 'KIT',
  Meal = 'MEAL',
  Packedmeal = 'PACKEDMEAL',
  Product = 'PRODUCT'
}

export type MarketableConnection = {
  __typename?: 'MarketableConnection';
  nodes: Array<IMarketable>;
  totalCount: Scalars['Int'];
};

export type MarketableFilter = INode & {
  __typename?: 'MarketableFilter';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<MartketableFilterItem>;
  message?: Maybe<MarketableFilterMessage>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MarketableFilterConnection = {
  __typename?: 'MarketableFilterConnection';
  nodes: Array<MarketableFilter>;
  totalCount: Scalars['Int'];
};

export type MarketableFilterMessage = {
  __typename?: 'MarketableFilterMessage';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum MarketableGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum MarketableKind {
  Kit = 'KIT',
  Meal = 'MEAL',
  Product = 'PRODUCT'
}

export enum MarketableRestrictions {
  GlutenFree = 'GLUTEN_FREE',
  LactoseFree = 'LACTOSE_FREE',
  LowCarb = 'LOW_CARB',
  NoRestrictions = 'NO_RESTRICTIONS',
  NutsFree = 'NUTS_FREE',
  SugarFree = 'SUGAR_FREE',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN'
}

export type MartketableFilterItem = {
  __typename?: 'MartketableFilterItem';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type Meal = IConsumable & IMarketable & INode & ISearchItem & {
  __typename?: 'Meal';
  additionalInfoBox?: Maybe<AdditionalInfoBoxSettings>;
  allergenics?: Maybe<Scalars['String']>;
  catalogueCategories: Array<CatalogueCategory>;
  categories: Array<Scalars['String']>;
  classification: MarketableClassification;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  directions?: Maybe<Scalars['String']>;
  distributionCentersNotSelling: Array<Scalars['String']>;
  distributionCentersSellingStatus: Array<DistributionCentersSellingStatus>;
  fiscal: Fiscal;
  gender: MarketableGender;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  imagesUrl: Array<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isLowCost: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  itemTags: ItemTagConnection;
  kind: MarketableKind;
  mainProduct: Scalars['String'];
  mayContain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nutritionFacts?: Maybe<NutritionFact>;
  nutritionalFacts: Array<NutritionalFact>;
  order?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceFrom?: Maybe<Scalars['Float']>;
  priorityMeasure?: Maybe<PriorityMeasure>;
  products: Array<Product>;
  promotionalSettings: PromotionalSettings;
  releaseDateString?: Maybe<Scalars['String']>;
  restrictions: Array<MarketableRestrictions>;
  sellingStatus: SellingStatus;
  seo: ProductSeo;
  sideProducts: Array<Scalars['String']>;
  sku: Scalars['String'];
  subtitle: Scalars['String'];
  tags: Array<Scalars['String']>;
  totalUnits?: Maybe<Scalars['Int']>;
  type: ConsumableKind;
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Int']>;
};


export type MealIsAvailableArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};


export type MealItemTagsArgs = {
  maxTags?: Maybe<Scalars['Float']>;
};

export type Mission = {
  __typename?: 'Mission';
  createdAt: Scalars['DateTime'];
  daysToExpirateReward?: Maybe<Scalars['Float']>;
  endsAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  minimalValue: Scalars['Float'];
  missionStatuses: Array<MissionStatus>;
  rewardDescription?: Maybe<Scalars['String']>;
  rewardMaxAbsoluteValue?: Maybe<Scalars['Float']>;
  rewardType: Scalars['String'];
  rewardValue: Scalars['Float'];
  ruleDescription?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MissionCartItem = {
  id: Scalars['String'];
  kind: MarketableKind;
  quantity: Scalars['Float'];
};

export type MissionPayload = {
  __typename?: 'MissionPayload';
  missions: Array<Mission>;
};

export type MissionStatus = {
  __typename?: 'MissionStatus';
  completedAt?: Maybe<Scalars['DateTime']>;
  isCompleted: Scalars['Boolean'];
};

export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER'
}

export enum MundipaggCardStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Expired = 'EXPIRED'
}

export enum MundipaggCardTypeEnum {
  Credit = 'CREDIT',
  Voucher = 'VOUCHER'
}

export enum MundipaggStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addToCart: AddToCartPayload;
  addToWishlist: AddToWishlistPayload;
  applyCoupon: ApplyCouponPayload;
  authenticateUserAndResetPasswordByToken: AuthenticateUserPayload;
  cartSync: CartSyncPayload;
  changePassword: ChangePasswordPayload;
  changePasswordWithToken: ChangePasswordPayload;
  ckUpdateOrderSurvey: CkUpdateOrderSurveyResponse;
  claimCart: ClaimCartPayload;
  clearRemovedProducts: ClearRemovedProductPayload;
  createB2bSale: B2BSale;
  createWishlist: CreateWishlistPayload;
  deleteAccount: DeleteAccountPayload;
  editAddress: EditAddressPayload;
  emailSignIn: AuthPayload;
  facebookSignIn: SocialAuthPayload;
  googleSignIn: SocialAuthPayload;
  makePayment: MakePaymentPayload;
  makePaymentWithAntifraud: MakePaymentPayload;
  processPendingSales: ProcessPendingSalesResponse;
  refreshToken: AuthPayload;
  removeAddress: RemoveAddressPayload;
  removeCard: RemoveCardPayload;
  removeFromWishlist: RemoveFromWishlistPayload;
  removeUnavailableItemsFromCart?: Maybe<RemoveUnavailableItemsFromCartPayload>;
  resetPasswordRequest: ResetPasswordRequestPayload;
  saleReschedule: SaleReschedulePayload;
  saveCard: SaveCardPayload;
  saveDeliveryAddress: SaveDeliveryAddressPayload;
  saveLojinhaUserSession: LojinhaSessionPayload;
  savePickupAddress: SavePickupAddressPayload;
  sendSMSCode: CellphoneValidationSendCodePayload;
  signInWithApple: SocialAuthPayload;
  signUpEmail: AuthPayload;
  surveyAnswerUpdate: SurveyAnswer;
  surveySignIn: AuthPayload;
  updateCellphoneValidationExpiration: CellphoneValidationUpdate;
  updateUser: UpdateUserPayload;
  validateCellphoneAccount: CellphoneValidationValidateAccountPayload;
  validateSMSCode: CellphoneValidationValidateCodePayload;
};


export type MutationAddToCartArgs = {
  input: AddToCartInput;
};


export type MutationAddToWishlistArgs = {
  input: AddToWishlistInput;
};


export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};


export type MutationAuthenticateUserAndResetPasswordByTokenArgs = {
  input: AuthenticateUserInput;
};


export type MutationCartSyncArgs = {
  input?: Maybe<CartSyncInput>;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangePasswordWithTokenArgs = {
  input: ChangePasswordWithTokenInput;
};


export type MutationCkUpdateOrderSurveyArgs = {
  input: CkUpdateOrderSurveyInput;
};


export type MutationClaimCartArgs = {
  input: ClaimCartInput;
};


export type MutationClearRemovedProductsArgs = {
  input: ClearRemovedProductsInput;
};


export type MutationCreateB2bSaleArgs = {
  input: CreateB2bSaleInput;
};


export type MutationCreateWishlistArgs = {
  input: CreateWishlistInput;
};


export type MutationEditAddressArgs = {
  input: EditAddressInput;
};


export type MutationEmailSignInArgs = {
  input: EmailSignIn;
};


export type MutationFacebookSignInArgs = {
  input: FacebookSignIn;
};


export type MutationGoogleSignInArgs = {
  input: GoogleSignIn;
};


export type MutationMakePaymentArgs = {
  input: MakePaymentInput;
};


export type MutationMakePaymentWithAntifraudArgs = {
  input: MakePaymentInput;
};


export type MutationProcessPendingSalesArgs = {
  secretKey: Scalars['String'];
};


export type MutationRemoveAddressArgs = {
  input: RemoveAddressInput;
};


export type MutationRemoveCardArgs = {
  input?: Maybe<RemoveCardInput>;
};


export type MutationRemoveFromWishlistArgs = {
  input: RemoveFromWishlistInput;
};


export type MutationRemoveUnavailableItemsFromCartArgs = {
  cartId: Scalars['String'];
  distributionCenterId: Scalars['String'];
};


export type MutationResetPasswordRequestArgs = {
  email: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  skipRecaptchaToken?: Maybe<Scalars['String']>;
};


export type MutationSaleRescheduleArgs = {
  input: SaleRescheduleInput;
};


export type MutationSaveCardArgs = {
  input: SaveCardInput;
};


export type MutationSaveDeliveryAddressArgs = {
  input: SaveDeliveryAddressInput;
};


export type MutationSaveLojinhaUserSessionArgs = {
  isFeatureLojinha?: Maybe<Scalars['Boolean']>;
  sessionId: Scalars['String'];
};


export type MutationSavePickupAddressArgs = {
  input: SavePickupAddressInput;
};


export type MutationSendSmsCodeArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationSignInWithAppleArgs = {
  input: SignInWithAppleInput;
};


export type MutationSignUpEmailArgs = {
  input: EmailSignUp;
};


export type MutationSurveyAnswerUpdateArgs = {
  answerId: Scalars['ID'];
  surveyAnswer: SurveyAnswerInput;
};


export type MutationSurveySignInArgs = {
  input: SignInSurveyRequest;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationValidateSmsCodeArgs = {
  phoneNumber: Scalars['String'];
  tokenCode: Scalars['String'];
};

export type NearestDcByCityCoordinatesPayload = {
  __typename?: 'NearestDCByCityCoordinatesPayload';
  distributionCenterId?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type NearestDistributionCenterByCityCoordinatesInput = {
  city: Scalars['String'];
  coordinates: CoordinatesInput;
  saleChannel?: Maybe<SaleChannel>;
  state: Scalars['String'];
};

export type NutritionFact = {
  __typename?: 'NutritionFact';
  facts: Array<NutritionalFact>;
  portionSize: Scalars['String'];
  portionUnit: Scalars['String'];
};

export type NutritionalFact = {
  __typename?: 'NutritionalFact';
  dailyValueAbsolute: Scalars['Float'];
  dailyValuePercentage: Scalars['Float'];
  label: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type OpeningInfo = {
  __typename?: 'OpeningInfo';
  endDay: StoreWeekday;
  startDay: StoreWeekday;
  workingInfo: Array<WorkingInfo>;
};

export type OrderBy = {
  direction?: Maybe<OrderByDirection>;
  field: Scalars['String'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderQuestion = {
  __typename?: 'OrderQuestion';
  answered: Scalars['Boolean'];
  name: OrderSurveyQuestionName;
  required?: Maybe<Scalars['Boolean']>;
};

export type OrderSurvey = {
  __typename?: 'OrderSurvey';
  completed: Scalars['Boolean'];
  id: Scalars['String'];
  questions: Array<OrderQuestion>;
};

export enum OrderSurveyQuestionName {
  AppScore = 'APP_SCORE',
  AppScoreObservations = 'APP_SCORE_OBSERVATIONS',
  DeliveryScore = 'DELIVERY_SCORE',
  DeliveryScoreObservations = 'DELIVERY_SCORE_OBSERVATIONS',
  FoodScore = 'FOOD_SCORE',
  FoodScoreObservations = 'FOOD_SCORE_OBSERVATIONS',
  Nps = 'NPS'
}

export type Page = {
  __typename?: 'Page';
  banner?: Maybe<Banner>;
  bannerApp?: Maybe<Banner>;
  components: SimpleComponentConnection;
  description: Scalars['String'];
  displays: DisplayConnection;
  dynamicDisplay?: Maybe<DynamicDisplay>;
  isSubcategory?: Maybe<Scalars['Boolean']>;
  seo: PageSeo;
  slug: Scalars['String'];
  title: Scalars['String'];
};


export type PageDisplaysArgs = {
  includeUserKits?: Maybe<Scalars['Boolean']>;
};

export type PageSeo = {
  __typename?: 'PageSeo';
  description: Scalars['String'];
  title: Scalars['String'];
};

export enum PaymentMethodBrand {
  Alelo = 'ALELO',
  Amex = 'AMEX',
  Aura = 'AURA',
  Ben = 'BEN',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  Greencard = 'GREENCARD',
  Hipercard = 'HIPERCARD',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  SodexoAlimentacao = 'SODEXO_ALIMENTACAO',
  SodexoRefeicao = 'SODEXO_REFEICAO',
  Ticket = 'TICKET',
  Unionpay = 'UNIONPAY',
  Unknown = 'UNKNOWN',
  UpBrasil = 'UP_BRASIL',
  Verocard = 'VEROCARD',
  Visa = 'VISA',
  VrAlimentacao = 'VR_ALIMENTACAO',
  VrRefeicao = 'VR_REFEICAO'
}

export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  nodes: Array<IPaymentMethod>;
  totalCount: Scalars['Int'];
};

export enum PaymentMethodType {
  Boleto = 'BOLETO',
  CreditCard = 'CREDIT_CARD',
  DebitOffline = 'DEBIT_OFFLINE',
  MarketVoucherOffline = 'MARKET_VOUCHER_OFFLINE',
  MarketVoucherOnline = 'MARKET_VOUCHER_ONLINE',
  PaymentOffline = 'PAYMENT_OFFLINE',
  Pix = 'PIX',
  RestaurantVoucherOffline = 'RESTAURANT_VOUCHER_OFFLINE',
  RestaurantVoucherOnline = 'RESTAURANT_VOUCHER_ONLINE',
  TicketOnline = 'TICKET_ONLINE',
  Unknown = 'UNKNOWN',
  Voucher = 'VOUCHER',
  VoucherOffline = 'VOUCHER_OFFLINE'
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  OnDelivery = 'ON_DELIVERY',
  Paid = 'PAID',
  Pending = 'PENDING',
  PendingAntifraudCapture = 'PENDING_ANTIFRAUD_CAPTURE',
  Processing = 'PROCESSING',
  Waiting = 'WAITING'
}

export enum Period {
  Afternoon = 'AFTERNOON',
  Morning = 'MORNING',
  Night = 'NIGHT'
}

export type PickupAddress = {
  __typename?: 'PickupAddress';
  centerId: Scalars['ID'];
  lastUsedAt: Scalars['DateTime'];
};

export type PixPaymentMethod = IPaymentMethod & {
  __typename?: 'PixPaymentMethod';
  id?: Maybe<Scalars['String']>;
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export enum PriorityMeasure {
  Grammage = 'GRAMMAGE',
  Units = 'UNITS'
}

export type ProcessPendingSalesResponse = {
  __typename?: 'ProcessPendingSalesResponse';
  numberOfOrders?: Maybe<Scalars['Float']>;
  success: Scalars['Boolean'];
};

export type Product = IConsumable & IMarketable & INode & ISearchItem & {
  __typename?: 'Product';
  additionalInfoBox?: Maybe<AdditionalInfoBoxSettings>;
  allergenics?: Maybe<Scalars['String']>;
  brandSettings?: Maybe<BrandSettings>;
  catalogueCategories: Array<CatalogueCategory>;
  categories: Array<Scalars['String']>;
  classification: MarketableClassification;
  /** @deprecated New cooking methods are available in "cookingTimes" field. */
  cookingTimeMicrowave?: Maybe<CookingTime>;
  /** @deprecated New cooking methods are available in "cookingTimes" field. */
  cookingTimeOven?: Maybe<CookingTime>;
  /** @deprecated New cooking methods are available in "cookingTimes" field. */
  cookingTimeWaterBath?: Maybe<CookingTime>;
  cookingTimes?: Maybe<Array<CookingTimeItem>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  directions?: Maybe<Scalars['String']>;
  distributionCentersNotSelling: Array<Scalars['String']>;
  distributionCentersSellingStatus: Array<DistributionCentersSellingStatus>;
  fiscal: Fiscal;
  forbiddenCombinations: Array<Product>;
  gender: MarketableGender;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  imagesUrl: Array<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAgeRestricted: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isLowCost: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  itemTags: ItemTagConnection;
  kind: MarketableKind;
  mayContain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nutritionFacts?: Maybe<NutritionFact>;
  nutritionalFacts: Array<NutritionalFact>;
  order?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceFrom?: Maybe<Scalars['Float']>;
  priorityMeasure?: Maybe<PriorityMeasure>;
  promotionalSettings: PromotionalSettings;
  recipeTags?: Maybe<Array<RecipeTag>>;
  releaseDateString?: Maybe<Scalars['String']>;
  restrictions: Array<MarketableRestrictions>;
  sellingStatus: SellingStatus;
  seo: ProductSeo;
  sku: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  totalUnits?: Maybe<Scalars['Int']>;
  type: ConsumableKind;
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  variants?: Maybe<ProductVariant>;
  weight?: Maybe<Scalars['Int']>;
};


export type ProductIsAvailableArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};


export type ProductItemTagsArgs = {
  maxTags?: Maybe<Scalars['Float']>;
};

export type ProductPosition = {
  __typename?: 'ProductPosition';
  day: Scalars['Float'];
  meal: Scalars['String'];
};

export type ProductSeo = {
  __typename?: 'ProductSeo';
  keywords?: Maybe<Array<Scalars['String']>>;
  metaDescription?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum ProductType {
  BabyFood = 'BABY_FOOD',
  Basket = 'BASKET',
  EasterEggs = 'EASTER_EGGS',
  Frozen = 'FROZEN',
  GreenGrocer = 'GREEN_GROCER',
  Refrigerated = 'REFRIGERATED',
  Snacks = 'SNACKS'
}

export type ProductVariant = INode & {
  __typename?: 'ProductVariant';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
};

export enum PromotionType {
  Discount = 'DISCOUNT',
  Donation = 'DONATION'
}

export type PromotionalSettings = {
  __typename?: 'PromotionalSettings';
  isPromotional?: Maybe<Scalars['Boolean']>;
  promotionType?: Maybe<PromotionType>;
  promotionalPercentage: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  availableDates: SlotConnection;
  availableExpressDeliveryTypes: ExpressDeliveryTypeConnection;
  b2bSale?: Maybe<B2BSale>;
  calculateSaleLoyaltyCredits: CalculateSaleLoyaltyCreditsPayload;
  cartRecommendations: Array<IMarketable>;
  checkoutRecommendations: DynamicDisplay;
  cmsPage?: Maybe<CmsPage>;
  distributionCenters: DistributionCenterConnection;
  dynamicDisplay: DynamicDisplay;
  dynamicPage?: Maybe<DynamicPage>;
  dynamicPageController?: Maybe<DynamicPageController>;
  earlyValidateCoupon: CouponEarlyValidationPayload;
  estimateShipping: EstimateShipping;
  externalMundipaggCards?: Maybe<ExternalMundipaggCardsPayload>;
  freeShippingValue: Scalars['Int'];
  getClosestCartMissionToAccomplish?: Maybe<ClosestCartMissionToAccomplishPayload>;
  getLoyaltyStatement: LoyaltyStatementPayload;
  getMissions: MissionPayload;
  kit: Kit;
  kitBySlug: Kit;
  marketableFilters?: Maybe<MarketableFilterConnection>;
  meal: Meal;
  mealBySlug: Meal;
  mundipaggStatus: MundipaggStatus;
  nearestDistributionCenterByCityCoordinates: NearestDcByCityCoordinatesPayload;
  page?: Maybe<Page>;
  product?: Maybe<Product>;
  productBySlug: Product;
  rewardTiers: RewardTiersPayload;
  sale?: Maybe<Sale>;
  saleByCartId?: Maybe<Sale>;
  search: Search;
  searchAddress?: Maybe<SearchAddressResponse>;
  /** @deprecated CK service has been discontinued */
  store?: Maybe<Store>;
  /** @deprecated CK service has been discontinued */
  stores: StoreConnection;
  survey?: Maybe<SurveyWithMarketables>;
  surveyAnswer?: Maybe<SurveyAnswer>;
  validateCartRestrictions: Array<IMarketable>;
  validateCoordinates: ValidateCoordinatesPayload;
  version: Scalars['String'];
  viewer: Viewer;
};


export type QueryAvailableDatesArgs = {
  input: AvailableDatesInput;
};


export type QueryAvailableExpressDeliveryTypesArgs = {
  input: AvailableExpressDeliveryTypesInput;
};


export type QueryB2bSaleArgs = {
  order: Scalars['Int'];
};


export type QueryCalculateSaleLoyaltyCreditsArgs = {
  input: CalcSaleLoyaltyCreditsInput;
};


export type QueryCartRecommendationsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  skusToFilter?: Maybe<Array<Scalars['String']>>;
};


export type QueryCmsPageArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userProfile?: Maybe<IcmsUserProfiles>;
};


export type QueryDistributionCentersArgs = {
  isPickupDisabled?: Maybe<Scalars['Boolean']>;
  saleChannel?: Maybe<SaleChannel>;
};


export type QueryDynamicPageArgs = {
  appVersionName?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  pageName?: Maybe<Scalars['String']>;
};


export type QueryDynamicPageControllerArgs = {
  appVersionName?: Maybe<Scalars['String']>;
  pageControllerName: Scalars['String'];
};


export type QueryEarlyValidateCouponArgs = {
  input: CouponEarlyValidationInput;
};


export type QueryEstimateShippingArgs = {
  input: EstimateShippingInput;
};


export type QueryExternalMundipaggCardsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetClosestCartMissionToAccomplishArgs = {
  input: ClosestCartMissionToAccomplishInput;
};


export type QueryGetLoyaltyStatementArgs = {
  appVersionName?: Maybe<Scalars['String']>;
};


export type QueryGetMissionsArgs = {
  CDId?: Maybe<Scalars['String']>;
};


export type QueryKitArgs = {
  id: Scalars['String'];
};


export type QueryKitBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryMealArgs = {
  id: Scalars['String'];
};


export type QueryMealBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryNearestDistributionCenterByCityCoordinatesArgs = {
  input: NearestDistributionCenterByCityCoordinatesInput;
};


export type QueryPageArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userProfile?: Maybe<IcmsUserProfiles>;
};


export type QueryProductArgs = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryProductBySlugArgs = {
  slug: Scalars['String'];
};


export type QuerySaleArgs = {
  order: Scalars['Int'];
};


export type QuerySaleByCartIdArgs = {
  cartId: Scalars['String'];
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySearchAddressArgs = {
  input: SearchAddressInput;
};


export type QueryStoreArgs = {
  coordinates?: Maybe<UserCoordinatesInput>;
  id: Scalars['String'];
};


export type QueryStoresArgs = {
  coordinates?: Maybe<UserCoordinatesInput>;
  distributionCenterId: Scalars['String'];
};


export type QuerySurveyArgs = {
  surveyTemplateId: Scalars['ID'];
};


export type QueryValidateCartRestrictionsArgs = {
  cartId: Scalars['String'];
  distributionCenterId: Scalars['String'];
};


export type QueryValidateCoordinatesArgs = {
  input: ValidateAddressInput;
};

export type Question = {
  __typename?: 'Question';
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<Scalars['String']>>;
  range?: Maybe<Range>;
  required: QuestionRequired;
  type: QuestionType;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  nodes: Array<Question>;
  totalCount: Scalars['Int'];
};

export enum QuestionRequired {
  False = 'FALSE',
  Range = 'RANGE',
  True = 'TRUE'
}

export enum QuestionType {
  Check = 'CHECK',
  Radio = 'RADIO',
  Text = 'TEXT'
}

export type Range = {
  __typename?: 'Range';
  max: Scalars['String'];
  min: Scalars['String'];
};

export enum RecipeTag {
  ContainsOrganic = 'CONTAINS_ORGANIC',
  OrganicProduct = 'ORGANIC_PRODUCT',
  ProductWithOrganicIngredients = 'PRODUCT_WITH_ORGANIC_INGREDIENTS',
  SustainableBeef = 'SUSTAINABLE_BEEF',
  SustainableChicken = 'SUSTAINABLE_CHICKEN',
  SustainableFish = 'SUSTAINABLE_FISH'
}

export type RemoveAddressInput = {
  address: RemoveDeliveryAddressInput;
};

export type RemoveAddressPayload = {
  __typename?: 'RemoveAddressPayload';
  user: User;
};

export type RemoveCardInput = {
  cardId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentMethodBrand?: Maybe<PaymentMethodBrand>;
  paymentMethodType?: Maybe<PaymentMethodType>;
};

export type RemoveCardPayload = {
  __typename?: 'RemoveCardPayload';
  success: Scalars['Boolean'];
  user: User;
};

export type RemoveDeliveryAddressInput = {
  complement?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type RemoveFromWishlistInput = {
  marketableId: Scalars['String'];
  marketableKind: MarketableKind;
  wishlistId: Scalars['Int'];
};

export type RemoveFromWishlistPayload = {
  __typename?: 'RemoveFromWishlistPayload';
  wishlist: Wishlist;
};

export type RemoveUnavailableItemsFromCartPayload = {
  __typename?: 'RemoveUnavailableItemsFromCartPayload';
  cart?: Maybe<Cart>;
  removedItems: Array<IMarketable>;
};

export type RemovedProducts = {
  __typename?: 'RemovedProducts';
  fromPositions: Array<ProductPosition>;
  removedProduct: Scalars['String'];
};

export type ResetPasswordRequestPayload = {
  __typename?: 'ResetPasswordRequestPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RestaurantVoucherOfflinePaymentMethod = IPaymentMethod & {
  __typename?: 'RestaurantVoucherOfflinePaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  id?: Maybe<Scalars['String']>;
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type RestaurantVoucherOnlinePaymentMethod = IPaymentMethod & {
  __typename?: 'RestaurantVoucherOnlinePaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  cardId: Scalars['String'];
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastDigits: Scalars['String'];
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type ReviewedProducts = {
  __typename?: 'ReviewedProducts';
  comment?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['Float']>;
  optIn: Scalars['Boolean'];
  product: Scalars['String'];
};

export type ReviewedProductsInput = {
  comment?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['Float']>;
  optIn: Scalars['Boolean'];
  product: Scalars['String'];
};

export type RewardTiersPayload = {
  __typename?: 'RewardTiersPayload';
  rewardTiers: Array<LoyaltyTier>;
};

export type Sale = INode & {
  __typename?: 'Sale';
  cart: SaleCart;
  createdAt: Scalars['DateTime'];
  deliveryStatus: DeliveryStatus;
  id: Scalars['ID'];
  isFromSubscription: Scalars['Boolean'];
  loyaltyCreditAmount: Scalars['Float'];
  loyaltyCreditDaysToExpirate: Scalars['Float'];
  order: Scalars['Int'];
  paymentStatus: PaymentStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type SaleCart = {
  __typename?: 'SaleCart';
  billing?: Maybe<CartBilling>;
  deliverySettings?: Maybe<CartDeliverySettings>;
  id: Scalars['ID'];
  items: Array<SaleCartItem>;
  itemsCount: Scalars['Int'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  marketingEmailsCount: Scalars['Float'];
  productTypes: Array<ProductType>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type SaleCartItem = {
  __typename?: 'SaleCartItem';
  classification: MarketableClassification;
  id: Scalars['ID'];
  isGiftCoupon: Scalars['Boolean'];
  item: IMarketable;
  kind: MarketableKind;
  quantity: Scalars['Float'];
  refPage?: Maybe<Scalars['String']>;
};

export enum SaleChannel {
  CloudKitchen = 'CLOUD_KITCHEN',
  Ecommerce = 'ECOMMERCE',
  Station = 'STATION'
}

export type SaleConnection = {
  __typename?: 'SaleConnection';
  nodes: Array<Sale>;
  totalCount: Scalars['Int'];
};

export type SaleRescheduleInput = {
  chosenDate: Scalars['String'];
  chosenPeriod: SaleReschedulePeriodInput;
  order: Scalars['Int'];
};

export type SaleReschedulePayload = {
  __typename?: 'SaleReschedulePayload';
  sale: Sale;
  success: Scalars['Boolean'];
};

export type SaleReschedulePeriodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  startHour: Scalars['Float'];
  timeRange: Array<Scalars['Float']>;
};

export type SaveCardInput = {
  card: SaveCardInputCardInfo;
  cardType: PaymentMethodType;
  oneDollarCheck?: Maybe<Scalars['Boolean']>;
};

export type SaveCardInputCardInfo = {
  cvv?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  holderDocument?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  pagarmeNonce?: Maybe<Scalars['String']>;
  paypalNonce?: Maybe<Scalars['String']>;
};

export type SaveCardPayload = {
  __typename?: 'SaveCardPayload';
  success: Scalars['Boolean'];
  user: User;
};

export type SaveDeliveryAddressFieldsInput = {
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  coordinates: CoordinatesInput;
  country: Scalars['String'];
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type SaveDeliveryAddressInput = {
  address: SaveDeliveryAddressFieldsInput;
  saleChannel?: Maybe<SaleChannel>;
};

export type SaveDeliveryAddressPayload = {
  __typename?: 'SaveDeliveryAddressPayload';
  success: Scalars['Boolean'];
  user: User;
};

export type SavePickupAddressInput = {
  centerId: Scalars['String'];
};

export type SavePickupAddressPayload = {
  __typename?: 'SavePickupAddressPayload';
  user: User;
};

export type Search = {
  __typename?: 'Search';
  results: MarketableConnection;
  term: Scalars['String'];
};

export type SearchAddressInput = {
  number?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type SearchAddressResponse = {
  __typename?: 'SearchAddressResponse';
  city: Scalars['String'];
  coordinates?: Maybe<Coordinates>;
  country: Scalars['String'];
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export enum SearchCategories {
  Carb = 'CARB',
  DocesESalgados = 'DOCES_E_SALGADOS',
  MassasEMolhos = 'MASSAS_E_MOLHOS',
  Pastas = 'PASTAS',
  Proteinas = 'PROTEINAS',
  Snacks = 'SNACKS',
  Sopas = 'SOPAS',
  Vegetais = 'VEGETAIS'
}

export type SearchInput = {
  categories?: Maybe<Array<SearchCategories>>;
  dcSellingStatuses?: Maybe<Array<DcSellingStatus>>;
  distributionCenterId?: Maybe<Scalars['String']>;
  searchType?: Maybe<SearchType>;
  term: Scalars['String'];
};

export enum SearchType {
  Global = 'GLOBAL',
  KitEdition = 'KIT_EDITION'
}

export enum SellingStatus {
  Deactivated = 'DEACTIVATED',
  Deprecated = 'DEPRECATED',
  PreSale = 'PRE_SALE',
  Selling = 'SELLING'
}

export type SignInSurveyRequest = {
  surveyToken: Scalars['String'];
};

export type SignInWithAppleInput = {
  fullName?: Maybe<Scalars['String']>;
  identityToken: Scalars['String'];
};

export type SimpleComponent = Banner | CmsContainer | Display | HighlightStories;

export type SimpleComponentConnection = {
  __typename?: 'SimpleComponentConnection';
  nodes: Array<SimpleComponent>;
  totalCount: Scalars['Int'];
};

export type Slot = {
  __typename?: 'Slot';
  availablePeriods: Array<AvailablePeriod>;
  day: Scalars['Int'];
  dayString?: Maybe<Scalars['String']>;
  distributionCenter?: Maybe<Scalars['String']>;
  fullDate: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  month: Month;
  periods: Array<AvailablePeriod>;
  weekday: Weekday;
};

export type SlotConnection = {
  __typename?: 'SlotConnection';
  nodes: Array<Slot>;
  totalCount: Scalars['Int'];
};

export type SocialAuthPayload = {
  __typename?: 'SocialAuthPayload';
  accessToken: Scalars['String'];
  isNewUser: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  user: User;
};

export type StatementCredit = {
  __typename?: 'StatementCredit';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  missionTitle?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
  saleChannel?: Maybe<SaleChannel>;
  statementCreditType: StatementCreditType;
};

export enum StatementCreditType {
  Add = 'ADD',
  Canceled = 'CANCELED',
  Consume = 'CONSUME',
  Expired = 'EXPIRED',
  Generic = 'GENERIC'
}

export type Store = INode & {
  __typename?: 'Store';
  bannerUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deliveryFee?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  distributionCenterId: Scalars['String'];
  estimatedDeliveryTime: EstimatedDeliveryTime;
  hours: StoreHours;
  id: Scalars['ID'];
  isOpen: Scalars['Boolean'];
  name: Scalars['String'];
  nextPeriod?: Maybe<WorkingPeriod>;
  pageUrl: Scalars['String'];
  shortDescription: Scalars['String'];
  status: StoreStatus;
  storeOrder?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type StoreConnection = {
  __typename?: 'StoreConnection';
  nodes: Array<Store>;
  totalCount: Scalars['Int'];
};

export type StoreHours = {
  __typename?: 'StoreHours';
  now?: Maybe<WorkingHour>;
  openingInfo: Array<OpeningInfo>;
  /** @deprecated Use field openingInfo */
  saturday: Array<WorkingHour>;
  /** @deprecated Use field openingInfo */
  weekday: Array<WorkingHour>;
};

export enum StoreStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Paused = 'PAUSED'
}

export enum StoreWeekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Survey = INode & {
  __typename?: 'Survey';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfProducts: Scalars['Float'];
  questions: Array<Question>;
  updatedAt: Scalars['DateTime'];
};

export type SurveyAnswer = INode & {
  __typename?: 'SurveyAnswer';
  answers?: Maybe<Answer>;
  createdAt: Scalars['DateTime'];
  expired: Scalars['Boolean'];
  id: Scalars['ID'];
  sale?: Maybe<Scalars['String']>;
  status?: Maybe<AnswerStatus>;
  survey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<Scalars['String']>;
};

export type SurveyAnswerConnection = {
  __typename?: 'SurveyAnswerConnection';
  nodes: Array<SurveyAnswer>;
  totalCount: Scalars['Int'];
};

export type SurveyAnswerInput = {
  answers?: Maybe<AnswerInput>;
  sale?: Maybe<Scalars['String']>;
  status?: Maybe<AnswerStatus>;
  survey?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  nodes: Array<Survey>;
  totalCount: Scalars['Int'];
};

export type SurveyWithMarketables = {
  __typename?: 'SurveyWithMarketables';
  selectedMarketables: Array<IMarketable>;
  surveyTemplate: Survey;
};

export type TicketOnlinePaymentMethod = IPaymentMethod & {
  __typename?: 'TicketOnlinePaymentMethod';
  brand?: Maybe<PaymentMethodBrand>;
  cardId: Scalars['String'];
  expirationDate: Scalars['String'];
  holderDocument?: Maybe<Scalars['String']>;
  holderName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastDigits: Scalars['String'];
  lastUsedAt: Scalars['DateTime'];
  saleChannels: Array<SaleChannel>;
  type: PaymentMethodType;
};

export type UpdateUserInput = {
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  rawPassword?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = {
  __typename?: 'User';
  addresses: AddressConnection;
  availableForCK: Scalars['Boolean'];
  cellphoneValidation?: Maybe<CellphoneValidation>;
  /** @deprecated CK service has been discontinued */
  ckOrders: CkOrderConnection;
  couponUsages: UserCreditUsageConnection;
  cpf?: Maybe<Scalars['String']>;
  creditStatement: UserCreditStatement;
  email: Scalars['String'];
  facebookId?: Maybe<Scalars['String']>;
  firstMission?: Maybe<Mission>;
  gender?: Maybe<UserGender>;
  id: Scalars['ID'];
  isFirstBuy: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notifications: UserNotificationConnection;
  ownCoupon?: Maybe<Coupon>;
  paymentMethods: PaymentMethodConnection;
  phoneNumber?: Maybe<Scalars['String']>;
  referralCouponCode?: Maybe<Scalars['String']>;
  referralCredit?: Maybe<Scalars['Float']>;
  referralDiscount?: Maybe<Scalars['Float']>;
  referralRewardAmount?: Maybe<Scalars['Float']>;
  sales: SaleConnection;
  userRewardTier?: Maybe<UserLoyaltyTier>;
  wishlists: WishlistConnection;
};


export type UserAddressesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type UserCkOrdersArgs = {
  filter?: Maybe<CkOrdersPalantirFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type UserPaymentMethodsArgs = {
  saleChannel?: Maybe<SaleChannel>;
  skipHolderDocumentFilter?: Maybe<Scalars['Boolean']>;
};


export type UserSalesArgs = {
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBy>;
  skip?: Maybe<Scalars['Int']>;
};

export type UserCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type UserCreditStatement = {
  __typename?: 'UserCreditStatement';
  nearestExpirationDate?: Maybe<Scalars['DateTime']>;
};

export type UserCreditUsage = {
  __typename?: 'UserCreditUsage';
  amount?: Maybe<Scalars['Float']>;
  userName: Scalars['String'];
};

export type UserCreditUsageConnection = {
  __typename?: 'UserCreditUsageConnection';
  nodes: Array<UserCreditUsage>;
  totalCount: Scalars['Int'];
};

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type UserLoyaltyTier = {
  __typename?: 'UserLoyaltyTier';
  ckRewardPercentage: Scalars['Float'];
  daysToExpirate: Scalars['Float'];
  firstMinimumEcommercePurchase: Scalars['Float'];
  firstRewardPercentage: Scalars['Float'];
  id: Scalars['ID'];
  minimumEcommercePurchase: Scalars['Float'];
  name: Scalars['String'];
  rewardPercentage: Scalars['Float'];
  staysInRewardTierUntil?: Maybe<Scalars['DateTime']>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  shouldBeShown: Scalars['Boolean'];
  type: Scalars['String'];
};

export type UserNotificationConnection = {
  __typename?: 'UserNotificationConnection';
  nodes: Array<UserNotification>;
  totalCount: Scalars['Int'];
};

export type ValidateAddressInput = {
  coordinates: CoordinatesInput;
  saleChannel?: Maybe<SaleChannel>;
};

export type ValidateCoordinatesPayload = {
  __typename?: 'ValidateCoordinatesPayload';
  ckDistributionCenter?: Maybe<DistributionCenter>;
  /** @deprecated use ckDistributionCenter or ecommDistributionCenter instead */
  distributionCenter?: Maybe<DistributionCenter>;
  ecommDistributionCenter?: Maybe<DistributionCenter>;
  /** @deprecated CK service has been discontinued */
  stores: StoreConnection;
  valid: Scalars['Boolean'];
};

export type Viewer = {
  __typename?: 'Viewer';
  cart?: Maybe<Cart>;
  user: User;
};


export type ViewerCartArgs = {
  input?: Maybe<ClaimCartInput>;
};

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['Int'];
  items: MarketableConnection;
};


export type WishlistItemsArgs = {
  distributionCenterId?: Maybe<Scalars['String']>;
};

export type WishlistConnection = {
  __typename?: 'WishlistConnection';
  nodes: Array<Wishlist>;
  totalCount: Scalars['Int'];
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  closingHour: Scalars['Float'];
  closingMinute: Scalars['Float'];
  id: Scalars['ID'];
  openingHour: Scalars['Float'];
  openingMinute: Scalars['Float'];
  weekday: StoreWeekday;
};

export type WorkingInfo = {
  __typename?: 'WorkingInfo';
  closingHour: Scalars['Int'];
  closingMinute: Scalars['Int'];
  openingHour: Scalars['Int'];
  openingMinute: Scalars['Int'];
};

export type WorkingPeriod = {
  __typename?: 'WorkingPeriod';
  closesAt: Scalars['DateTime'];
  opensAt: Scalars['DateTime'];
};

export type CheckoutHeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutHeaderQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
        )> }
      ) }
    )> }
  ) }
);

type PaymentMethodsFragment_BoletoPaymentMethod_Fragment = (
  { __typename?: 'BoletoPaymentMethod' }
  & Pick<BoletoPaymentMethod, 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_CreditCardPaymentMethod_Fragment = (
  { __typename?: 'CreditCardPaymentMethod' }
  & Pick<CreditCardPaymentMethod, 'brand' | 'cardId' | 'holderName' | 'holderDocument' | 'expirationDate' | 'lastDigits' | 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment = (
  { __typename?: 'MarketVoucherOfflinePaymentMethod' }
  & Pick<MarketVoucherOfflinePaymentMethod, 'brand' | 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment = (
  { __typename?: 'MarketVoucherOnlinePaymentMethod' }
  & Pick<MarketVoucherOnlinePaymentMethod, 'brand' | 'cardId' | 'holderName' | 'holderDocument' | 'expirationDate' | 'lastDigits' | 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_PixPaymentMethod_Fragment = (
  { __typename?: 'PixPaymentMethod' }
  & Pick<PixPaymentMethod, 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment = (
  { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
  & Pick<RestaurantVoucherOfflinePaymentMethod, 'brand' | 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment = (
  { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
  & Pick<RestaurantVoucherOnlinePaymentMethod, 'brand' | 'cardId' | 'holderName' | 'holderDocument' | 'expirationDate' | 'lastDigits' | 'id' | 'type' | 'lastUsedAt'>
);

type PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment = (
  { __typename?: 'TicketOnlinePaymentMethod' }
  & Pick<TicketOnlinePaymentMethod, 'brand' | 'cardId' | 'holderName' | 'holderDocument' | 'expirationDate' | 'lastDigits' | 'id' | 'type' | 'lastUsedAt'>
);

export type PaymentMethodsFragmentFragment = PaymentMethodsFragment_BoletoPaymentMethod_Fragment | PaymentMethodsFragment_CreditCardPaymentMethod_Fragment | PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment | PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment | PaymentMethodsFragment_PixPaymentMethod_Fragment | PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment | PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment | PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment;

export type CheckoutContextQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutContextQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'freeShippingValue'>
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'phoneNumber' | 'name' | 'cpf' | 'isFirstBuy'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'zipCode' | 'complement' | 'neighborhood' | 'city' | 'state' | 'country' | 'deliveryCost'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<Coordinates, 'latitude' | 'longitude'>
          ), nearestDistributionCenter?: Maybe<(
            { __typename?: 'DistributionCenter' }
            & Pick<DistributionCenter, 'id'>
          )> }
        ) | (
          { __typename?: 'PickupAddress' }
          & Pick<PickupAddress, 'centerId' | 'lastUsedAt'>
        )> }
      ), paymentMethods: (
        { __typename?: 'PaymentMethodConnection' }
        & { nodes: Array<(
          { __typename?: 'BoletoPaymentMethod' }
          & PaymentMethodsFragment_BoletoPaymentMethod_Fragment
        ) | (
          { __typename?: 'CreditCardPaymentMethod' }
          & PaymentMethodsFragment_CreditCardPaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'PixPaymentMethod' }
          & PaymentMethodsFragment_PixPaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'TicketOnlinePaymentMethod' }
          & PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment
        )> }
      ) }
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { items: (
        { __typename?: 'CartItemConection' }
        & Pick<CartItemConection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'mainProduct' | 'sideProducts' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'isAgeRestricted' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ), billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )> }
    )> }
  ) }
);

export type CartIdQueryVariables = Exact<{ [key: string]: never; }>;


export type CartIdQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
    )> }
  ) }
);

export type AddToCartMutationVariables = Exact<{
  input: AddToCartInput;
}>;


export type AddToCartMutation = (
  { __typename?: 'Mutation' }
  & { addToCart: (
    { __typename?: 'AddToCartPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'createdAt' | 'id' | 'status' | 'lastUpdatedAt' | 'productTypes'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal' | 'total'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & Pick<CartItemConection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'isAgeRestricted' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

type AddressFragment_DeliveryAddress_Fragment = (
  { __typename: 'DeliveryAddress' }
  & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'neighborhood' | 'complement' | 'zipCode' | 'city' | 'state' | 'country' | 'deliveryCost'>
  & { coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<Coordinates, 'latitude' | 'longitude'>
  ), nearestDistributionCenter?: Maybe<(
    { __typename?: 'DistributionCenter' }
    & Pick<DistributionCenter, 'id'>
  )> }
);

type AddressFragment_PickupAddress_Fragment = (
  { __typename: 'PickupAddress' }
  & Pick<PickupAddress, 'lastUsedAt' | 'centerId'>
);

export type AddressFragmentFragment = AddressFragment_DeliveryAddress_Fragment | AddressFragment_PickupAddress_Fragment;

export type AddressesListQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressesListQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & AddressFragment_DeliveryAddress_Fragment
        ) | (
          { __typename?: 'PickupAddress' }
          & AddressFragment_PickupAddress_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type AddressesListCartQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressesListCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )> }
    )> }
  ) }
);

export type RemoveDeliveryAddressMutationVariables = Exact<{
  input: RemoveAddressInput;
}>;


export type RemoveDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & { removeAddress: (
    { __typename?: 'RemoveAddressPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & AddressFragment_DeliveryAddress_Fragment
        ) | (
          { __typename?: 'PickupAddress' }
          & AddressFragment_PickupAddress_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type RemoveCardOnListMutationVariables = Exact<{
  input?: Maybe<RemoveCardInput>;
}>;


export type RemoveCardOnListMutation = (
  { __typename?: 'Mutation' }
  & { removeCard: (
    { __typename?: 'RemoveCardPayload' }
    & Pick<RemoveCardPayload, 'success'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { paymentMethods: (
        { __typename?: 'PaymentMethodConnection' }
        & { nodes: Array<(
          { __typename?: 'BoletoPaymentMethod' }
          & PaymentMethodsFragment_BoletoPaymentMethod_Fragment
        ) | (
          { __typename?: 'CreditCardPaymentMethod' }
          & PaymentMethodsFragment_CreditCardPaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'PixPaymentMethod' }
          & PaymentMethodsFragment_PixPaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'TicketOnlinePaymentMethod' }
          & PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type PickupAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type PickupAddressesQuery = (
  { __typename?: 'Query' }
  & { distributionCenters: (
    { __typename?: 'DistributionCenterConnection' }
    & { nodes: Array<(
      { __typename?: 'DistributionCenter' }
      & Pick<DistributionCenter, 'id' | 'name' | 'isPickupDisabled'>
      & { address: (
        { __typename?: 'DistributionCenterAddress' }
        & Pick<DistributionCenterAddress, 'city' | 'number' | 'street' | 'neighborhood' | 'zipCode' | 'state' | 'country' | 'complement'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<Coordinates, 'latitude' | 'longitude'>
        ) }
      ) }
    )> }
  ), viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<{ __typename?: 'DeliveryAddress' } | (
          { __typename?: 'PickupAddress' }
          & Pick<PickupAddress, 'centerId' | 'lastUsedAt'>
        )> }
      ) }
    ) }
  ) }
);

export type SavePickupAddressMutationVariables = Exact<{
  input: SavePickupAddressInput;
}>;


export type SavePickupAddressMutation = (
  { __typename?: 'Mutation' }
  & { savePickupAddress: (
    { __typename?: 'SavePickupAddressPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & Pick<AddressConnection, 'totalCount'>
        & { nodes: Array<{ __typename?: 'DeliveryAddress' } | (
          { __typename?: 'PickupAddress' }
          & Pick<PickupAddress, 'lastUsedAt' | 'centerId'>
        )> }
      ) }
    ) }
  ) }
);

export type ViewerCartQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type UnavailableItemsQueryVariables = Exact<{
  distributionCenterId: Scalars['String'];
  cartId: Scalars['String'];
}>;


export type UnavailableItemsQuery = (
  { __typename?: 'Query' }
  & { validateCartRestrictions: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'id' | 'name' | 'imageUrl' | 'price' | 'priceFrom'>
  ) | (
    { __typename?: 'Meal' }
    & Pick<Meal, 'id' | 'name' | 'imageUrl' | 'price' | 'priceFrom'>
  ) | (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'imageUrl' | 'price' | 'priceFrom'>
  )> }
);

export type RemoveUnavailableItemsMutationVariables = Exact<{
  distributionCenterId: Scalars['String'];
  cartId: Scalars['String'];
}>;


export type RemoveUnavailableItemsMutation = (
  { __typename?: 'Mutation' }
  & { removeUnavailableItemsFromCart?: Maybe<(
    { __typename?: 'RemoveUnavailableItemsFromCartPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'status' | 'lastUpdatedAt' | 'productTypes'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal' | 'total'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & Pick<CartItemConection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type PersonalInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'cpf' | 'phoneNumber'>
    ) }
  ) }
);

export type CartGatekeeperUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CartGatekeeperUserQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
    )> }
  ) }
);

export type TrackerUserQueryVariables = Exact<{ [key: string]: never; }>;


export type TrackerUserQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'phoneNumber' | 'name'>
      & { userRewardTier?: Maybe<(
        { __typename?: 'UserLoyaltyTier' }
        & Pick<UserLoyaltyTier, 'id' | 'name' | 'rewardPercentage' | 'daysToExpirate' | 'firstRewardPercentage'>
      )>, addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & Pick<DeliveryAddress, 'city' | 'state' | 'country'>
        ) | { __typename?: 'PickupAddress' }> }
      ), sales: (
        { __typename?: 'SaleConnection' }
        & Pick<SaleConnection, 'totalCount'>
      ) }
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & Pick<CartItemConection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'name' | 'sku' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id' | 'name' | 'sku' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'name' | 'sku' | 'price' | 'priceFrom'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type SaveLojinhaUserSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
  isFeatureLojinha?: Maybe<Scalars['Boolean']>;
}>;


export type SaveLojinhaUserSessionMutation = (
  { __typename?: 'Mutation' }
  & { saveLojinhaUserSession: (
    { __typename?: 'LojinhaSessionPayload' }
    & Pick<LojinhaSessionPayload, 'success' | 'reason'>
  ) }
);

export type CheckoutCompleteQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CheckoutCompleteQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'loyaltyCreditAmount' | 'paymentStatus'>
    & { cart: (
      { __typename?: 'SaleCart' }
      & Pick<SaleCart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'total'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'CartBillingPaymentMethodBoleto' }
          & Pick<CartBillingPaymentMethodBoleto, 'boletoUrl' | 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOffline' }
          & Pick<CartBillingPaymentMethodOffline, 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOnline' }
          & Pick<CartBillingPaymentMethodOnline, 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodPix' }
          & Pick<CartBillingPaymentMethodPix, 'type'>
        )> }
      )>, deliverySettings?: Maybe<(
        { __typename?: 'CartDeliverySettings' }
        & Pick<CartDeliverySettings, 'chosenDate' | 'kind'>
        & { address: (
          { __typename?: 'CartDeliverySettingsAddress' }
          & Pick<CartDeliverySettingsAddress, 'city' | 'neighborhood' | 'number' | 'state' | 'street' | 'complement'>
        ), chosenPeriod?: Maybe<(
          { __typename?: 'CartDeliveryChosenPeriod' }
          & Pick<CartDeliveryChosenPeriod, 'id' | 'name' | 'timeRange'>
        )> }
      )> }
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'referralCouponCode' | 'referralRewardAmount'>
    ) }
  )> }
);

export type PurchaseDetailsQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type PurchaseDetailsQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'createdAt'>
    & { cart: (
      { __typename?: 'SaleCart' }
      & Pick<SaleCart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal' | 'discount' | 'referralDiscount' | 'delivery'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'CartBillingPaymentMethodBoleto' }
          & Pick<CartBillingPaymentMethodBoleto, 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOffline' }
          & Pick<CartBillingPaymentMethodOffline, 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOnline' }
          & Pick<CartBillingPaymentMethodOnline, 'type'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodPix' }
          & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
        )> }
      )>, deliverySettings?: Maybe<(
        { __typename?: 'CartDeliverySettings' }
        & Pick<CartDeliverySettings, 'authorize' | 'kind'>
      )>, items: Array<(
        { __typename?: 'SaleCartItem' }
        & Pick<SaleCartItem, 'quantity'>
        & { item: (
          { __typename?: 'Kit' }
          & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'classification' | 'sku' | 'name' | 'price' | 'priceFrom'>
        ) | (
          { __typename?: 'Meal' }
          & Pick<Meal, 'id' | 'imageUrl' | 'kind' | 'classification' | 'sku' | 'name' | 'price' | 'priceFrom'>
        ) | (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'imageUrl' | 'kind' | 'classification' | 'sku' | 'name' | 'price' | 'priceFrom'>
        ) }
      )> }
    ) }
  )> }
);

export type SaveDeliveryAddressMutationVariables = Exact<{
  input: SaveDeliveryAddressInput;
}>;


export type SaveDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & { saveDeliveryAddress: (
    { __typename?: 'SaveDeliveryAddressPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & Pick<AddressConnection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'zipCode' | 'complement' | 'neighborhood' | 'city' | 'state' | 'country'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<Coordinates, 'latitude' | 'longitude'>
          ) }
        ) | { __typename?: 'PickupAddress' }> }
      ) }
    ) }
  ) }
);

export type AddressGeoSearchQueryVariables = Exact<{
  input: SearchAddressInput;
}>;


export type AddressGeoSearchQuery = (
  { __typename?: 'Query' }
  & { searchAddress?: Maybe<(
    { __typename?: 'SearchAddressResponse' }
    & Pick<SearchAddressResponse, 'city' | 'country' | 'neighborhood' | 'number' | 'state' | 'street' | 'zipCode'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'latitude' | 'longitude'>
    )> }
  )> }
);

export type AddressSearchQueryVariables = Exact<{
  input: SearchAddressInput;
}>;


export type AddressSearchQuery = (
  { __typename?: 'Query' }
  & { searchAddress?: Maybe<(
    { __typename?: 'SearchAddressResponse' }
    & Pick<SearchAddressResponse, 'city' | 'country' | 'neighborhood' | 'number' | 'state' | 'street' | 'zipCode'>
  )> }
);

export type DeliveryAddressListQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryAddressListQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & AddressParts_DeliveryAddress_Fragment
        ) | (
          { __typename?: 'PickupAddress' }
          & AddressParts_PickupAddress_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type RemoveAddressMutationVariables = Exact<{
  input: RemoveAddressInput;
}>;


export type RemoveAddressMutation = (
  { __typename?: 'Mutation' }
  & { removeAddress: (
    { __typename?: 'RemoveAddressPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & AddressParts_DeliveryAddress_Fragment
        ) | (
          { __typename?: 'PickupAddress' }
          & AddressParts_PickupAddress_Fragment
        )> }
      ) }
    ) }
  ) }
);

type AddressParts_DeliveryAddress_Fragment = (
  { __typename: 'DeliveryAddress' }
  & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'neighborhood' | 'complement' | 'zipCode' | 'city' | 'state' | 'country'>
  & { coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<Coordinates, 'latitude' | 'longitude'>
  ) }
);

type AddressParts_PickupAddress_Fragment = (
  { __typename: 'PickupAddress' }
  & Pick<PickupAddress, 'lastUsedAt'>
);

export type AddressPartsFragment = AddressParts_DeliveryAddress_Fragment | AddressParts_PickupAddress_Fragment;

export type UserDeliveryAddressListQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDeliveryAddressListQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & AddressParts_DeliveryAddress_Fragment
        ) | (
          { __typename?: 'PickupAddress' }
          & AddressParts_PickupAddress_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type DeliveryCartQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )> }
    )> }
  ) }
);

export type EstimateShippingQueryVariables = Exact<{
  input: EstimateShippingInput;
}>;


export type EstimateShippingQuery = (
  { __typename?: 'Query' }
  & { estimateShipping: (
    { __typename?: 'EstimateShipping' }
    & Pick<EstimateShipping, 'deliveryCost'>
    & { distributionCenter?: Maybe<(
      { __typename?: 'DistributionCenter' }
      & Pick<DistributionCenter, 'id'>
    )> }
  ) }
);

export type ClaimCartMutationVariables = Exact<{
  input: ClaimCartInput;
}>;


export type ClaimCartMutation = (
  { __typename?: 'Mutation' }
  & { claimCart: (
    { __typename?: 'ClaimCartPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
        )> }
      ) }
    )> }
  ) }
);

export type ResetPasswordRequestMutationVariables = Exact<{
  recaptchaToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
}>;


export type ResetPasswordRequestMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordRequest: (
    { __typename?: 'ResetPasswordRequestPayload' }
    & Pick<ResetPasswordRequestPayload, 'success' | 'message'>
  ) }
);

export type EmailSignInMutationVariables = Exact<{
  input: EmailSignIn;
}>;


export type EmailSignInMutation = (
  { __typename?: 'Mutation' }
  & { emailSignIn: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { cellphoneValidation?: Maybe<(
        { __typename?: 'CellphoneValidation' }
        & Pick<CellphoneValidation, 'status' | 'validatedAccountEmail'>
      )> }
    ) }
  ) }
);

export type EmailSignUpMutationVariables = Exact<{
  input: EmailSignUp;
}>;


export type EmailSignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUpEmail: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { cellphoneValidation?: Maybe<(
        { __typename?: 'CellphoneValidation' }
        & Pick<CellphoneValidation, 'status' | 'validatedAccountEmail'>
      )> }
    ) }
  ) }
);

export type FacebookSignInMutationVariables = Exact<{
  input: FacebookSignIn;
}>;


export type FacebookSignInMutation = (
  { __typename?: 'Mutation' }
  & { facebookSignIn: (
    { __typename?: 'SocialAuthPayload' }
    & Pick<SocialAuthPayload, 'accessToken' | 'refreshToken' | 'isNewUser'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { cellphoneValidation?: Maybe<(
        { __typename?: 'CellphoneValidation' }
        & Pick<CellphoneValidation, 'status' | 'validatedAccountEmail'>
      )> }
    ) }
  ) }
);

export type GoogleSignInMutationVariables = Exact<{
  input: GoogleSignIn;
}>;


export type GoogleSignInMutation = (
  { __typename?: 'Mutation' }
  & { googleSignIn: (
    { __typename?: 'SocialAuthPayload' }
    & Pick<SocialAuthPayload, 'accessToken' | 'refreshToken' | 'isNewUser'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { cellphoneValidation?: Maybe<(
        { __typename?: 'CellphoneValidation' }
        & Pick<CellphoneValidation, 'status' | 'validatedAccountEmail'>
      )> }
    ) }
  ) }
);

export type AppleSignInMutationVariables = Exact<{
  input: SignInWithAppleInput;
}>;


export type AppleSignInMutation = (
  { __typename?: 'Mutation' }
  & { signInWithApple: (
    { __typename?: 'SocialAuthPayload' }
    & Pick<SocialAuthPayload, 'accessToken' | 'refreshToken' | 'isNewUser'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { cellphoneValidation?: Maybe<(
        { __typename?: 'CellphoneValidation' }
        & Pick<CellphoneValidation, 'status' | 'validatedAccountEmail'>
      )> }
    ) }
  ) }
);

export type GetUserRewardTierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRewardTierQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & { userRewardTier?: Maybe<(
        { __typename?: 'UserLoyaltyTier' }
        & Pick<UserLoyaltyTier, 'name' | 'rewardPercentage' | 'firstRewardPercentage' | 'daysToExpirate'>
      )> }
    ) }
  ) }
);

export type CalculateCashbackQueryVariables = Exact<{
  input: CalcSaleLoyaltyCreditsInput;
}>;


export type CalculateCashbackQuery = (
  { __typename?: 'Query' }
  & { calculateSaleLoyaltyCredits: (
    { __typename?: 'CalculateSaleLoyaltyCreditsPayload' }
    & Pick<CalculateSaleLoyaltyCreditsPayload, 'total' | 'daysToExpire'>
  ) }
);

export type SaveCardUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type SaveCardUserInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'cpf'>
    ) }
  ) }
);

export type SaveCardMutationVariables = Exact<{
  input: SaveCardInput;
}>;


export type SaveCardMutation = (
  { __typename?: 'Mutation' }
  & { saveCard: (
    { __typename?: 'SaveCardPayload' }
    & Pick<SaveCardPayload, 'success'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { paymentMethods: (
        { __typename?: 'PaymentMethodConnection' }
        & { nodes: Array<(
          { __typename?: 'BoletoPaymentMethod' }
          & PaymentMethodsFragment_BoletoPaymentMethod_Fragment
        ) | (
          { __typename?: 'CreditCardPaymentMethod' }
          & PaymentMethodsFragment_CreditCardPaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'PixPaymentMethod' }
          & PaymentMethodsFragment_PixPaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'TicketOnlinePaymentMethod' }
          & PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type GetSavedCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedCardsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { paymentMethods: (
        { __typename?: 'PaymentMethodConnection' }
        & { nodes: Array<(
          { __typename?: 'BoletoPaymentMethod' }
          & PaymentMethodsFragment_BoletoPaymentMethod_Fragment
        ) | (
          { __typename?: 'CreditCardPaymentMethod' }
          & PaymentMethodsFragment_CreditCardPaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'PixPaymentMethod' }
          & PaymentMethodsFragment_PixPaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'TicketOnlinePaymentMethod' }
          & PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type RemoveCardMutationVariables = Exact<{
  input?: Maybe<RemoveCardInput>;
}>;


export type RemoveCardMutation = (
  { __typename?: 'Mutation' }
  & { removeCard: (
    { __typename?: 'RemoveCardPayload' }
    & Pick<RemoveCardPayload, 'success'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { paymentMethods: (
        { __typename?: 'PaymentMethodConnection' }
        & { nodes: Array<(
          { __typename?: 'BoletoPaymentMethod' }
          & PaymentMethodsFragment_BoletoPaymentMethod_Fragment
        ) | (
          { __typename?: 'CreditCardPaymentMethod' }
          & PaymentMethodsFragment_CreditCardPaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'MarketVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_MarketVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'PixPaymentMethod' }
          & PaymentMethodsFragment_PixPaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOfflinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOfflinePaymentMethod_Fragment
        ) | (
          { __typename?: 'RestaurantVoucherOnlinePaymentMethod' }
          & PaymentMethodsFragment_RestaurantVoucherOnlinePaymentMethod_Fragment
        ) | (
          { __typename?: 'TicketOnlinePaymentMethod' }
          & PaymentMethodsFragment_TicketOnlinePaymentMethod_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type UpdateUserModalMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserModalMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'cpf' | 'name' | 'phoneNumber'>
    ) }
  ) }
);

export type PixScreenQueryQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type PixScreenQueryQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'paymentStatus'>
    & { cart: (
      { __typename?: 'SaleCart' }
      & Pick<SaleCart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'total'>
        & { paymentMethod?: Maybe<{ __typename?: 'CartBillingPaymentMethodBoleto' } | { __typename?: 'CartBillingPaymentMethodOffline' } | { __typename?: 'CartBillingPaymentMethodOnline' } | (
          { __typename?: 'CartBillingPaymentMethodPix' }
          & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
        )> }
      )> }
    ) }
  )> }
);

export type GetUserCartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'facebookId' | 'phoneNumber' | 'referralCredit'>
      & { sales: (
        { __typename?: 'SaleConnection' }
        & Pick<SaleConnection, 'totalCount'>
      ) }
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'status' | 'lastUpdatedAt' | 'productTypes'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal' | 'total'>
      )>, items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'mainProduct' | 'sideProducts' | 'id' | 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'isAgeRestricted' | 'id' | 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type GetInvoiceInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'referralCredit'>
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )> }
    )> }
  ) }
);

export type MakePaymentMutationVariables = Exact<{
  input: MakePaymentInput;
}>;


export type MakePaymentMutation = (
  { __typename?: 'Mutation' }
  & { makePayment: (
    { __typename?: 'MakePaymentPayload' }
    & Pick<MakePaymentPayload, 'valid'>
    & { sale: (
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'order' | 'paymentStatus'>
      & { cart: (
        { __typename?: 'SaleCart' }
        & Pick<SaleCart, 'id'>
        & { items: Array<(
          { __typename?: 'SaleCartItem' }
          & Pick<SaleCartItem, 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) }
        )>, billing?: Maybe<(
          { __typename?: 'CartBilling' }
          & Pick<CartBilling, 'total' | 'delivery'>
          & { paymentMethod?: Maybe<(
            { __typename?: 'CartBillingPaymentMethodBoleto' }
            & Pick<CartBillingPaymentMethodBoleto, 'type' | 'boletoUrl'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodOffline' }
            & Pick<CartBillingPaymentMethodOffline, 'type' | 'brand'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodOnline' }
            & Pick<CartBillingPaymentMethodOnline, 'type' | 'brand'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodPix' }
            & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type FindSaleByCartIdQueryVariables = Exact<{
  cartId: Scalars['String'];
}>;


export type FindSaleByCartIdQuery = (
  { __typename?: 'Query' }
  & { saleByCartId?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'order' | 'paymentStatus'>
  )> }
);

export type MakePaymentWithAntifraudMutationVariables = Exact<{
  input: MakePaymentInput;
}>;


export type MakePaymentWithAntifraudMutation = (
  { __typename?: 'Mutation' }
  & { makePaymentWithAntifraud: (
    { __typename?: 'MakePaymentPayload' }
    & Pick<MakePaymentPayload, 'valid'>
    & { sale: (
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'order' | 'paymentStatus'>
      & { cart: (
        { __typename?: 'SaleCart' }
        & Pick<SaleCart, 'id'>
        & { items: Array<(
          { __typename?: 'SaleCartItem' }
          & Pick<SaleCartItem, 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'sku' | 'name' | 'kind' | 'price' | 'priceFrom'>
          ) }
        )>, billing?: Maybe<(
          { __typename?: 'CartBilling' }
          & Pick<CartBilling, 'total' | 'delivery'>
          & { paymentMethod?: Maybe<(
            { __typename?: 'CartBillingPaymentMethodBoleto' }
            & Pick<CartBillingPaymentMethodBoleto, 'type' | 'boletoUrl'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodOffline' }
            & Pick<CartBillingPaymentMethodOffline, 'type' | 'brand'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodOnline' }
            & Pick<CartBillingPaymentMethodOnline, 'type' | 'brand'>
          ) | (
            { __typename?: 'CartBillingPaymentMethodPix' }
            & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type CartSyncOnReviewMutationVariables = Exact<{
  input?: Maybe<CartSyncInput>;
}>;


export type CartSyncOnReviewMutation = (
  { __typename?: 'Mutation' }
  & { cartSync: (
    { __typename?: 'CartSyncPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'mainProduct' | 'sideProducts' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'isAgeRestricted' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type NumberOfOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type NumberOfOrdersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { sales: (
        { __typename?: 'SaleConnection' }
        & Pick<SaleConnection, 'totalCount'>
      ) }
    ) }
  ) }
);

export type RemoveUnavailableItemsFromCartMutationVariables = Exact<{
  distributionCenterId: Scalars['String'];
  cartId: Scalars['String'];
}>;


export type RemoveUnavailableItemsFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeUnavailableItemsFromCart?: Maybe<(
    { __typename?: 'RemoveUnavailableItemsFromCartPayload' }
    & { removedItems: Array<(
      { __typename?: 'Kit' }
      & Pick<Kit, 'name'>
    ) | (
      { __typename?: 'Meal' }
      & Pick<Meal, 'name'>
    ) | (
      { __typename?: 'Product' }
      & Pick<Product, 'name'>
    )>, cart?: Maybe<(
      { __typename?: 'Cart' }
      & { items: (
        { __typename?: 'CartItemConection' }
        & { nodes: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id' | 'quantity'>
          & { item: (
            { __typename?: 'Kit' }
            & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Meal' }
            & Pick<Meal, 'mainProduct' | 'sideProducts' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) | (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type CartFragmentFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'user'>
  & { items: (
    { __typename?: 'CartItemConection' }
    & Pick<CartItemConection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'id' | 'quantity'>
      & { item: (
        { __typename?: 'Kit' }
        & Pick<Kit, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
      ) | (
        { __typename?: 'Meal' }
        & Pick<Meal, 'mainProduct' | 'sideProducts' | 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
      ) | (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'imageUrl' | 'kind' | 'sku' | 'name' | 'price' | 'priceFrom' | 'classification'>
      ) }
    )> }
  ), billing?: Maybe<(
    { __typename?: 'CartBilling' }
    & Pick<CartBilling, 'subtotal'>
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email' | 'isFirstBuy'>
  & { addresses: (
    { __typename?: 'AddressConnection' }
    & { nodes: Array<(
      { __typename?: 'DeliveryAddress' }
      & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'zipCode' | 'complement' | 'neighborhood' | 'city' | 'state' | 'country'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      ), nearestDistributionCenter?: Maybe<(
        { __typename?: 'DistributionCenter' }
        & Pick<DistributionCenter, 'id'>
      )> }
    ) | { __typename?: 'PickupAddress' }> }
  ) }
);

export type FreeShippingValueQueryVariables = Exact<{ [key: string]: never; }>;


export type FreeShippingValueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'freeShippingValue'>
);

export type VitrineContextQueryVariables = Exact<{ [key: string]: never; }>;


export type VitrineContextQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'phoneNumber' | 'isFirstBuy' | 'referralRewardAmount'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'zipCode' | 'complement' | 'neighborhood' | 'city' | 'state' | 'country'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<Coordinates, 'latitude' | 'longitude'>
          ), nearestDistributionCenter?: Maybe<(
            { __typename?: 'DistributionCenter' }
            & Pick<DistributionCenter, 'id'>
          )> }
        ) | { __typename?: 'PickupAddress' }> }
      ) }
    ) }
  ) }
);

export type GetToCartQueryVariables = Exact<{
  input: ClaimCartInput;
}>;


export type GetToCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & CartFragmentFragment
    )> }
  ) }
);

export type ClaimSavedCartMutationVariables = Exact<{
  input: ClaimCartInput;
}>;


export type ClaimSavedCartMutation = (
  { __typename?: 'Mutation' }
  & { claimCart: (
    { __typename?: 'ClaimCartPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & CartFragmentFragment
    )> }
  ) }
);

export type GetNearestDistributionCenterByCityCoordinatesQueryVariables = Exact<{
  input: NearestDistributionCenterByCityCoordinatesInput;
}>;


export type GetNearestDistributionCenterByCityCoordinatesQuery = (
  { __typename?: 'Query' }
  & { nearestDistributionCenterByCityCoordinates: (
    { __typename?: 'NearestDCByCityCoordinatesPayload' }
    & Pick<NearestDcByCityCoordinatesPayload, 'valid' | 'distributionCenterId'>
  ) }
);

export type GetAddressesAndCartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressesAndCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & CartFragmentFragment
    )> }
  ) }
);

export type AllDistributionCentersQueryVariables = Exact<{
  isPickupDisabled?: Maybe<Scalars['Boolean']>;
}>;


export type AllDistributionCentersQuery = (
  { __typename?: 'Query' }
  & { distributionCenters: (
    { __typename?: 'DistributionCenterConnection' }
    & { nodes: Array<(
      { __typename?: 'DistributionCenter' }
      & Pick<DistributionCenter, 'id'>
    )> }
  ) }
);

export type GetIndicationInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndicationInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'referralCredit' | 'referralCouponCode' | 'referralRewardAmount' | 'referralDiscount'>
      & { ownCoupon?: Maybe<(
        { __typename?: 'Coupon' }
        & Pick<Coupon, 'usageCount'>
      )>, couponUsages: (
        { __typename?: 'UserCreditUsageConnection' }
        & Pick<UserCreditUsageConnection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'UserCreditUsage' }
          & Pick<UserCreditUsage, 'userName' | 'amount'>
        )> }
      ) }
    ) }
  ) }
);

export type SaleRescheduleMutationVariables = Exact<{
  input: SaleRescheduleInput;
}>;


export type SaleRescheduleMutation = (
  { __typename?: 'Mutation' }
  & { saleReschedule: (
    { __typename?: 'SaleReschedulePayload' }
    & Pick<SaleReschedulePayload, 'success'>
  ) }
);

export type GetOrderDetailsQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type GetOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'createdAt' | 'paymentStatus' | 'deliveryStatus'>
    & { cart: (
      { __typename?: 'SaleCart' }
      & Pick<SaleCart, 'itemsCount'>
      & { deliverySettings?: Maybe<(
        { __typename?: 'CartDeliverySettings' }
        & Pick<CartDeliverySettings, 'kind' | 'chosenDate' | 'distributionCenter' | 'canBeRescheduledUntil'>
        & { chosenPeriod?: Maybe<(
          { __typename?: 'CartDeliveryChosenPeriod' }
          & Pick<CartDeliveryChosenPeriod, 'id' | 'name' | 'timeRange'>
        )>, address: (
          { __typename?: 'CartDeliverySettingsAddress' }
          & Pick<CartDeliverySettingsAddress, 'number' | 'street' | 'neighborhood' | 'complement'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<Coordinates, 'latitude' | 'longitude'>
          ) }
        ) }
      )>, billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'delivery' | 'discount' | 'referralDiscount' | 'subtotal' | 'total'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'CartBillingPaymentMethodBoleto' }
          & Pick<CartBillingPaymentMethodBoleto, 'type' | 'boletoUrl'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOffline' }
          & Pick<CartBillingPaymentMethodOffline, 'type' | 'brand'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodOnline' }
          & Pick<CartBillingPaymentMethodOnline, 'type' | 'brand'>
        ) | (
          { __typename?: 'CartBillingPaymentMethodPix' }
          & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
        )> }
      )>, items: Array<(
        { __typename?: 'SaleCartItem' }
        & Pick<SaleCartItem, 'quantity'>
        & { item: (
          { __typename?: 'Kit' }
          & Pick<Kit, 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'id' | 'classification'>
        ) | (
          { __typename?: 'Meal' }
          & Pick<Meal, 'mainProduct' | 'sideProducts' | 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'id' | 'classification'>
        ) | (
          { __typename?: 'Product' }
          & Pick<Product, 'imageUrl' | 'kind' | 'name' | 'sku' | 'price' | 'priceFrom' | 'id' | 'classification'>
        ) }
      )> }
    ) }
  )> }
);

export type GetMyPurchasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPurchasesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { sales: (
        { __typename?: 'SaleConnection' }
        & { nodes: Array<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'createdAt' | 'deliveryStatus' | 'order' | 'paymentStatus'>
          & { cart: (
            { __typename?: 'SaleCart' }
            & { deliverySettings?: Maybe<(
              { __typename?: 'CartDeliverySettings' }
              & Pick<CartDeliverySettings, 'chosenDate'>
              & { chosenPeriod?: Maybe<(
                { __typename?: 'CartDeliveryChosenPeriod' }
                & Pick<CartDeliveryChosenPeriod, 'timeRange' | 'name'>
              )> }
            )>, billing?: Maybe<(
              { __typename?: 'CartBilling' }
              & Pick<CartBilling, 'total'>
              & { paymentMethod?: Maybe<(
                { __typename: 'CartBillingPaymentMethodBoleto' }
                & Pick<CartBillingPaymentMethodBoleto, 'type' | 'boletoUrl' | 'boletoLine'>
              ) | (
                { __typename: 'CartBillingPaymentMethodOffline' }
                & Pick<CartBillingPaymentMethodOffline, 'type' | 'brand'>
              ) | (
                { __typename: 'CartBillingPaymentMethodOnline' }
                & Pick<CartBillingPaymentMethodOnline, 'type' | 'brand'>
              ) | (
                { __typename: 'CartBillingPaymentMethodPix' }
                & Pick<CartBillingPaymentMethodPix, 'type' | 'qrCode' | 'qrCodeUrl' | 'expiresAt'>
              )> }
            )> }
          ) }
        )> }
      ) }
    ) }
  ) }
);

export type CmsContainerItemsFragment = (
  { __typename: 'CMSContainer' }
  & Pick<CmsContainer, 'id' | 'size' | 'layout' | 'slider'>
  & { components?: Maybe<Array<(
    { __typename?: 'CMSComponentContainerAccordion' }
    & Pick<CmsComponentContainerAccordion, 'id' | 'title' | 'description'>
    & { accordionItem: Array<(
      { __typename?: 'CMSAccordionItem' }
      & Pick<CmsAccordionItem, 'title' | 'text'>
    )> }
  ) | (
    { __typename?: 'CMSComponentContainerBanner' }
    & Pick<CmsComponentContainerBanner, 'id' | 'title' | 'description' | 'redirectUrl'>
    & { imageDesktop?: Maybe<(
      { __typename?: 'CMSUploadFile' }
      & Pick<CmsUploadFile, 'url'>
    )>, imageMobile?: Maybe<(
      { __typename?: 'CMSUploadFile' }
      & Pick<CmsUploadFile, 'url'>
    )> }
  ) | (
    { __typename?: 'CMSComponentContainerBannerBreadcrumb' }
    & Pick<CmsComponentContainerBannerBreadcrumb, 'id' | 'title' | 'description'>
    & { image?: Maybe<(
      { __typename?: 'CMSUploadFile' }
      & Pick<CmsUploadFile, 'url'>
    )> }
  ) | (
    { __typename?: 'CMSComponentContainerButton' }
    & Pick<CmsComponentContainerButton, 'id' | 'text' | 'redirectUrl' | 'size' | 'type' | 'block' | 'newTab'>
  ) | (
    { __typename?: 'CMSComponentContainerCallToAction' }
    & Pick<CmsComponentContainerCallToAction, 'id' | 'title' | 'text' | 'buttonText' | 'link'>
    & { color?: Maybe<(
      { __typename?: 'CMSColor' }
      & Pick<CmsColor, 'background'>
    )>, image?: Maybe<(
      { __typename?: 'CMSUploadFile' }
      & Pick<CmsUploadFile, 'url'>
    )> }
  ) | (
    { __typename?: 'CMSComponentContainerCustomHtml' }
    & Pick<CmsComponentContainerCustomHtml, 'id' | 'html' | 'head' | 'css' | 'javascript'>
  ) | { __typename?: 'CMSComponentContainerDeliveryAreaCard' } | (
    { __typename?: 'CMSComponentContainerDisplay' }
    & Pick<CmsComponentContainerDisplay, 'id' | 'title' | 'redirectUrl' | 'home' | 'subcategory' | 'cardSize' | 'items' | 'isAnchorable'>
  ) | (
    { __typename?: 'CMSComponentContainerHeader' }
    & Pick<CmsComponentContainerHeader, 'id' | 'title' | 'text'>
    & { color?: Maybe<(
      { __typename?: 'CMSColor' }
      & Pick<CmsColor, 'primary' | 'background'>
    )> }
  ) | (
    { __typename: 'CMSComponentContainerImage' }
    & Pick<CmsComponentContainerImage, 'id' | 'title' | 'description' | 'borderRadius' | 'isVertical'>
    & { image?: Maybe<(
      { __typename?: 'CMSUploadFile' }
      & Pick<CmsUploadFile, 'url'>
    )> }
  ) | (
    { __typename?: 'CMSComponentContainerNavigationAnchors' }
    & Pick<CmsComponentContainerNavigationAnchors, 'id' | 'description'>
  ) | (
    { __typename?: 'CMSComponentContainerProducerAreaCards' }
    & { cards?: Maybe<Array<(
      { __typename?: 'CMSComponentContainerProducerCard' }
      & Pick<CmsComponentContainerProducerCard, 'name' | 'occupation' | 'redirectUrl'>
      & { image?: Maybe<(
        { __typename?: 'CMSUploadFile' }
        & Pick<CmsUploadFile, 'url'>
      )> }
    )>> }
  ) | { __typename?: 'CMSComponentContainerProducerCard' } | (
    { __typename?: 'CMSComponentContainerQuote' }
    & Pick<CmsComponentContainerQuote, 'quote' | 'author'>
  ) | (
    { __typename?: 'CMSComponentContainerText' }
    & Pick<CmsComponentContainerText, 'anchorText' | 'customText' | 'isAnchorable'>
  ) | (
    { __typename: 'CMSComponentContainerVideo' }
    & Pick<CmsComponentContainerVideo, 'id' | 'title' | 'description' | 'url'>
  ) | (
    { __typename?: 'CMSComponentDeliveryAreaCards' }
    & { cards?: Maybe<Array<(
      { __typename?: 'CMSComponentContainerDeliveryAreaCard' }
      & Pick<CmsComponentContainerDeliveryAreaCard, 'state' | 'mainCity' | 'minorCities' | 'deliveryMapLink' | 'takeoffMapLink'>
    )>> }
  ) | (
    { __typename?: 'CMSComponentHighlightStoryHighlightStories' }
    & Pick<CmsComponentHighlightStoryHighlightStories, 'id'>
    & { highlights?: Maybe<Array<(
      { __typename?: 'CMSComponentContainerHighlightStory' }
      & Pick<CmsComponentContainerHighlightStory, 'title' | 'destinationUrl'>
      & { image?: Maybe<(
        { __typename?: 'CMSUploadFile' }
        & Pick<CmsUploadFile, 'url'>
      )>, distributionCenters?: Maybe<Array<(
        { __typename?: 'CMSDistributionCenter' }
        & Pick<CmsDistributionCenter, 'externalId'>
      )>>, userProfiles?: Maybe<Array<(
        { __typename?: 'CMSUserProfile' }
        & Pick<CmsUserProfile, 'type'>
      )>> }
    )>> }
  )>> }
);

type DisplayItems_Kit_Fragment = (
  { __typename?: 'Kit' }
  & Pick<Kit, 'isAvailable' | 'meanPricePerDay' | 'totalMealsCount' | 'description' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
  & { itemTags: (
    { __typename?: 'ItemTagConnection' }
    & Pick<ItemTagConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ItemTag' }
      & Pick<ItemTag, 'colorPalette' | 'content'>
    )> }
  ), seo: (
    { __typename?: 'ProductSeo' }
    & Pick<ProductSeo, 'url'>
  ) }
);

type DisplayItems_Meal_Fragment = (
  { __typename?: 'Meal' }
  & Pick<Meal, 'isAvailable' | 'weight' | 'mainProduct' | 'subtitle' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
  & { itemTags: (
    { __typename?: 'ItemTagConnection' }
    & Pick<ItemTagConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ItemTag' }
      & Pick<ItemTag, 'colorPalette' | 'content'>
    )> }
  ), seo: (
    { __typename?: 'ProductSeo' }
    & Pick<ProductSeo, 'url'>
  ) }
);

type DisplayItems_Product_Fragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'isAvailable' | 'isAgeRestricted' | 'weight' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
  & { itemTags: (
    { __typename?: 'ItemTagConnection' }
    & Pick<ItemTagConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ItemTag' }
      & Pick<ItemTag, 'colorPalette' | 'content'>
    )> }
  ), seo: (
    { __typename?: 'ProductSeo' }
    & Pick<ProductSeo, 'url'>
  ) }
);

export type DisplayItemsFragment = DisplayItems_Kit_Fragment | DisplayItems_Meal_Fragment | DisplayItems_Product_Fragment;

export type GetPageContentQueryVariables = Exact<{
  slug: Scalars['String'];
  distributionCenterId: Scalars['String'];
  userProfile: IcmsUserProfiles;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetPageContentQuery = (
  { __typename?: 'Query' }
  & { page?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'title' | 'description'>
    & { seo: (
      { __typename?: 'PageSeo' }
      & Pick<PageSeo, 'title' | 'description'>
    ), components: (
      { __typename?: 'SimpleComponentConnection' }
      & Pick<SimpleComponentConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename: 'Banner' }
        & Pick<Banner, 'title' | 'url' | 'description'>
      ) | (
        { __typename: 'CMSContainer' }
        & CmsContainerItemsFragment
      ) | (
        { __typename: 'Display' }
        & Pick<Display, 'id' | 'name' | 'isSubcategory' | 'slug' | 'kind' | 'classification'>
        & { itemsOptimized: Array<(
          { __typename?: 'Kit' }
          & DisplayItems_Kit_Fragment
        ) | (
          { __typename?: 'Meal' }
          & DisplayItems_Meal_Fragment
        ) | (
          { __typename?: 'Product' }
          & DisplayItems_Product_Fragment
        )> }
      ) | (
        { __typename: 'HighlightStories' }
        & Pick<HighlightStories, 'count'>
        & { stories?: Maybe<Array<(
          { __typename?: 'HighlightStory' }
          & Pick<HighlightStory, 'title' | 'listPosition' | 'imageUrl' | 'onlyOnDistributionCenters' | 'releaseDate'>
          & { content: (
            { __typename?: 'HighlighStoryContent' }
            & Pick<HighlighStoryContent, 'destinationUrl'>
          ) }
        )>> }
      )> }
    ) }
  )> }
);

export type GetHomeContentQueryVariables = Exact<{
  slug: Scalars['String'];
  distributionCenterId: Scalars['String'];
  userProfile: IcmsUserProfiles;
}>;


export type GetHomeContentQuery = (
  { __typename?: 'Query' }
  & { page?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'title' | 'description'>
    & { seo: (
      { __typename?: 'PageSeo' }
      & Pick<PageSeo, 'title' | 'description'>
    ), components: (
      { __typename?: 'SimpleComponentConnection' }
      & Pick<SimpleComponentConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename: 'Banner' }
        & Pick<Banner, 'title' | 'url' | 'description'>
      ) | (
        { __typename: 'CMSContainer' }
        & Pick<CmsContainer, 'id' | 'size' | 'layout' | 'slider'>
        & { components?: Maybe<Array<(
          { __typename?: 'CMSComponentContainerAccordion' }
          & Pick<CmsComponentContainerAccordion, 'id' | 'title' | 'description'>
          & { accordionItem: Array<(
            { __typename?: 'CMSAccordionItem' }
            & Pick<CmsAccordionItem, 'title' | 'text'>
          )> }
        ) | (
          { __typename?: 'CMSComponentContainerBanner' }
          & Pick<CmsComponentContainerBanner, 'id' | 'title' | 'description' | 'redirectUrl'>
          & { imageDesktop?: Maybe<(
            { __typename?: 'CMSUploadFile' }
            & Pick<CmsUploadFile, 'url'>
          )>, imageMobile?: Maybe<(
            { __typename?: 'CMSUploadFile' }
            & Pick<CmsUploadFile, 'url'>
          )> }
        ) | { __typename?: 'CMSComponentContainerBannerBreadcrumb' } | (
          { __typename?: 'CMSComponentContainerButton' }
          & Pick<CmsComponentContainerButton, 'id' | 'text' | 'redirectUrl' | 'size' | 'type' | 'block' | 'newTab'>
        ) | (
          { __typename?: 'CMSComponentContainerCallToAction' }
          & Pick<CmsComponentContainerCallToAction, 'id' | 'title' | 'text' | 'buttonText' | 'link'>
          & { color?: Maybe<(
            { __typename?: 'CMSColor' }
            & Pick<CmsColor, 'background'>
          )>, image?: Maybe<(
            { __typename?: 'CMSUploadFile' }
            & Pick<CmsUploadFile, 'url'>
          )> }
        ) | (
          { __typename?: 'CMSComponentContainerCustomHtml' }
          & Pick<CmsComponentContainerCustomHtml, 'id' | 'html' | 'head' | 'css' | 'javascript'>
        ) | { __typename?: 'CMSComponentContainerDeliveryAreaCard' } | (
          { __typename?: 'CMSComponentContainerDisplay' }
          & Pick<CmsComponentContainerDisplay, 'id' | 'title' | 'redirectUrl' | 'home' | 'subcategory' | 'cardSize' | 'items'>
        ) | (
          { __typename?: 'CMSComponentContainerHeader' }
          & Pick<CmsComponentContainerHeader, 'id' | 'title' | 'text'>
          & { color?: Maybe<(
            { __typename?: 'CMSColor' }
            & Pick<CmsColor, 'primary' | 'background'>
          )> }
        ) | (
          { __typename: 'CMSComponentContainerImage' }
          & Pick<CmsComponentContainerImage, 'id' | 'title' | 'description' | 'borderRadius' | 'isVertical'>
          & { image?: Maybe<(
            { __typename?: 'CMSUploadFile' }
            & Pick<CmsUploadFile, 'url'>
          )> }
        ) | { __typename?: 'CMSComponentContainerNavigationAnchors' } | (
          { __typename?: 'CMSComponentContainerProducerAreaCards' }
          & { cards?: Maybe<Array<(
            { __typename?: 'CMSComponentContainerProducerCard' }
            & Pick<CmsComponentContainerProducerCard, 'name' | 'occupation' | 'redirectUrl'>
            & { image?: Maybe<(
              { __typename?: 'CMSUploadFile' }
              & Pick<CmsUploadFile, 'url'>
            )> }
          )>> }
        ) | { __typename?: 'CMSComponentContainerProducerCard' } | (
          { __typename?: 'CMSComponentContainerQuote' }
          & Pick<CmsComponentContainerQuote, 'quote' | 'author'>
        ) | (
          { __typename?: 'CMSComponentContainerText' }
          & Pick<CmsComponentContainerText, 'customText'>
        ) | (
          { __typename: 'CMSComponentContainerVideo' }
          & Pick<CmsComponentContainerVideo, 'id' | 'title' | 'description' | 'url'>
        ) | (
          { __typename?: 'CMSComponentDeliveryAreaCards' }
          & { cards?: Maybe<Array<(
            { __typename?: 'CMSComponentContainerDeliveryAreaCard' }
            & Pick<CmsComponentContainerDeliveryAreaCard, 'state' | 'mainCity' | 'minorCities' | 'deliveryMapLink' | 'takeoffMapLink'>
          )>> }
        ) | (
          { __typename?: 'CMSComponentHighlightStoryHighlightStories' }
          & Pick<CmsComponentHighlightStoryHighlightStories, 'id'>
          & { highlights?: Maybe<Array<(
            { __typename?: 'CMSComponentContainerHighlightStory' }
            & Pick<CmsComponentContainerHighlightStory, 'title' | 'destinationUrl'>
            & { image?: Maybe<(
              { __typename?: 'CMSUploadFile' }
              & Pick<CmsUploadFile, 'url'>
            )>, distributionCenters?: Maybe<Array<(
              { __typename?: 'CMSDistributionCenter' }
              & Pick<CmsDistributionCenter, 'externalId'>
            )>>, userProfiles?: Maybe<Array<(
              { __typename?: 'CMSUserProfile' }
              & Pick<CmsUserProfile, 'type'>
            )>> }
          )>> }
        )>> }
      ) | { __typename: 'Display' } | (
        { __typename: 'HighlightStories' }
        & Pick<HighlightStories, 'count'>
        & { stories?: Maybe<Array<(
          { __typename?: 'HighlightStory' }
          & Pick<HighlightStory, 'title' | 'listPosition' | 'imageUrl' | 'onlyOnDistributionCenters' | 'releaseDate'>
          & { content: (
            { __typename?: 'HighlighStoryContent' }
            & Pick<HighlighStoryContent, 'destinationUrl'>
          ) }
        )>> }
      )> }
    ) }
  )> }
);

export type GetProfileInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'phoneNumber' | 'cpf'>
    ) }
  ) }
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'phoneNumber' | 'cpf'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'success'>
  ) }
);

export type SearchQueryVariables = Exact<{
  term: Scalars['String'];
  distributionCenterId?: Maybe<Scalars['String']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'Search' }
    & { results: (
      { __typename?: 'MarketableConnection' }
      & Pick<MarketableConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename?: 'Kit' }
        & Pick<Kit, 'isAvailable' | 'pricePerDay' | 'totalMealsCount' | 'description' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
        & { itemTags: (
          { __typename?: 'ItemTagConnection' }
          & Pick<ItemTagConnection, 'totalCount'>
          & { nodes: Array<(
            { __typename?: 'ItemTag' }
            & Pick<ItemTag, 'colorPalette' | 'content'>
          )> }
        ), seo: (
          { __typename?: 'ProductSeo' }
          & Pick<ProductSeo, 'url'>
        ) }
      ) | (
        { __typename?: 'Meal' }
        & Pick<Meal, 'isAvailable' | 'weight' | 'mainProduct' | 'subtitle' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
        & { itemTags: (
          { __typename?: 'ItemTagConnection' }
          & Pick<ItemTagConnection, 'totalCount'>
          & { nodes: Array<(
            { __typename?: 'ItemTag' }
            & Pick<ItemTag, 'colorPalette' | 'content'>
          )> }
        ), seo: (
          { __typename?: 'ProductSeo' }
          & Pick<ProductSeo, 'url'>
        ) }
      ) | (
        { __typename?: 'Product' }
        & Pick<Product, 'isAvailable' | 'isAgeRestricted' | 'weight' | 'id' | 'imageUrl' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'sellingStatus'>
        & { itemTags: (
          { __typename?: 'ItemTagConnection' }
          & Pick<ItemTagConnection, 'totalCount'>
          & { nodes: Array<(
            { __typename?: 'ItemTag' }
            & Pick<ItemTag, 'colorPalette' | 'content'>
          )> }
        ), seo: (
          { __typename?: 'ProductSeo' }
          & Pick<ProductSeo, 'url'>
        ) }
      )> }
    ) }
  ) }
);

export type GetKitQueryVariables = Exact<{
  slug: Scalars['String'];
  distributionCenterId?: Maybe<Scalars['String']>;
}>;


export type GetKitQuery = (
  { __typename?: 'Query' }
  & { kitBySlug: (
    { __typename?: 'Kit' }
    & Pick<Kit, 'id' | 'name' | 'kind' | 'classification' | 'sku' | 'price' | 'priceFrom' | 'description' | 'imageUrl' | 'sellingStatus' | 'isAvailable' | 'pricePerDay' | 'totalMealsCount'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ), firstOfferReplacement?: Maybe<(
      { __typename?: 'FirstOfferReplacementSettings' }
      & { firstBuy: (
        { __typename?: 'FirstOfferReplacementItem' }
        & Pick<FirstOfferReplacementItem, 'id' | 'sku' | 'name' | 'price' | 'priceFrom' | 'totalMealsCount' | 'imageUrl'>
      ), rebuy: (
        { __typename?: 'FirstOfferReplacementItem' }
        & Pick<FirstOfferReplacementItem, 'id' | 'sku' | 'name' | 'price' | 'priceFrom' | 'totalMealsCount' | 'imageUrl'>
      ) }
    )>, itemTags: (
      { __typename?: 'ItemTagConnection' }
      & Pick<ItemTagConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename?: 'ItemTag' }
        & Pick<ItemTag, 'colorPalette' | 'content'>
      )> }
    ), days: Array<(
      { __typename?: 'KitDay' }
      & Pick<KitDay, 'dayNumber' | 'totalKcal'>
      & { periods: Array<(
        { __typename?: 'KitDayPeriod' }
        & Pick<KitDayPeriod, 'name' | 'totalWeight' | 'totalPrice' | 'totalKcal'>
        & { products: Array<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'price' | 'priceFrom' | 'imageUrl' | 'sku'>
          & { seo: (
            { __typename?: 'ProductSeo' }
            & Pick<ProductSeo, 'url'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type GetMealQueryVariables = Exact<{
  slug: Scalars['String'];
  distributionCenterId?: Maybe<Scalars['String']>;
}>;


export type GetMealQuery = (
  { __typename?: 'Query' }
  & { mealBySlug: (
    { __typename?: 'Meal' }
    & Pick<Meal, 'name' | 'id' | 'sku' | 'categories' | 'kind' | 'classification' | 'price' | 'priceFrom' | 'description' | 'imageUrl' | 'weight' | 'unit' | 'allergenics' | 'sellingStatus' | 'subtitle' | 'mainProduct' | 'isAvailable'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ), itemTags: (
      { __typename?: 'ItemTagConnection' }
      & Pick<ItemTagConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename?: 'ItemTag' }
        & Pick<ItemTag, 'colorPalette' | 'content'>
      )> }
    ), products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'ingredients'>
    )>, nutritionFacts?: Maybe<(
      { __typename?: 'NutritionFact' }
      & Pick<NutritionFact, 'portionSize' | 'portionUnit'>
      & { facts: Array<(
        { __typename?: 'NutritionalFact' }
        & Pick<NutritionalFact, 'label' | 'value' | 'unit' | 'dailyValuePercentage'>
      )> }
    )> }
  ) }
);

export type CookingTimeFragmentFragment = (
  { __typename?: 'CookingTime' }
  & Pick<CookingTime, 'minutes' | 'seconds'>
);

export type GetProductQueryVariables = Exact<{
  slug: Scalars['String'];
  distributionCenterId?: Maybe<Scalars['String']>;
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { productBySlug: (
    { __typename?: 'Product' }
    & Pick<Product, 'name' | 'id' | 'sku' | 'categories' | 'kind' | 'classification' | 'price' | 'priceFrom' | 'description' | 'imageUrl' | 'weight' | 'unit' | 'sellingStatus' | 'allergenics' | 'isAgeRestricted' | 'isAvailable' | 'ingredients'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ), itemTags: (
      { __typename?: 'ItemTagConnection' }
      & Pick<ItemTagConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename?: 'ItemTag' }
        & Pick<ItemTag, 'colorPalette' | 'content'>
      )> }
    ), nutritionFacts?: Maybe<(
      { __typename?: 'NutritionFact' }
      & Pick<NutritionFact, 'portionSize' | 'portionUnit'>
      & { facts: Array<(
        { __typename?: 'NutritionalFact' }
        & Pick<NutritionalFact, 'label' | 'value' | 'unit' | 'dailyValuePercentage'>
      )> }
    )>, cookingTimeMicrowave?: Maybe<(
      { __typename?: 'CookingTime' }
      & CookingTimeFragmentFragment
    )>, cookingTimeOven?: Maybe<(
      { __typename?: 'CookingTime' }
      & CookingTimeFragmentFragment
    )>, cookingTimeWaterBath?: Maybe<(
      { __typename?: 'CookingTime' }
      & CookingTimeFragmentFragment
    )> }
  ) }
);

export type SurveySignInMutationVariables = Exact<{
  input: SignInSurveyRequest;
}>;


export type SurveySignInMutation = (
  { __typename?: 'Mutation' }
  & { surveySignIn: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
  ) }
);

export type SurveyAnswerQueryVariables = Exact<{ [key: string]: never; }>;


export type SurveyAnswerQuery = (
  { __typename?: 'Query' }
  & { surveyAnswer?: Maybe<(
    { __typename?: 'SurveyAnswer' }
    & Pick<SurveyAnswer, 'status' | 'expired' | 'id' | 'user' | 'survey' | 'sale'>
    & { answers?: Maybe<(
      { __typename?: 'Answer' }
      & { questions?: Maybe<Array<(
        { __typename?: 'AnsweredQuestions' }
        & Pick<AnsweredQuestions, 'name'>
      )>> }
    )> }
  )> }
);

export type SurveyAnswerUpdateMutationVariables = Exact<{
  surveyAnswer: SurveyAnswerInput;
  answerId: Scalars['ID'];
}>;


export type SurveyAnswerUpdateMutation = (
  { __typename?: 'Mutation' }
  & { surveyAnswerUpdate: (
    { __typename?: 'SurveyAnswer' }
    & Pick<SurveyAnswer, 'id' | 'status'>
  ) }
);

export type SurveyContentQueryVariables = Exact<{
  surveyTemplateId: Scalars['ID'];
}>;


export type SurveyContentQuery = (
  { __typename?: 'Query' }
  & { survey?: Maybe<(
    { __typename?: 'SurveyWithMarketables' }
    & { selectedMarketables: Array<(
      { __typename?: 'Kit' }
      & Pick<Kit, 'id' | 'name' | 'imageUrl' | 'sku'>
    ) | (
      { __typename?: 'Meal' }
      & Pick<Meal, 'id' | 'name' | 'imageUrl' | 'sku'>
    ) | (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'imageUrl' | 'sku'>
    )>, surveyTemplate: (
      { __typename?: 'Survey' }
      & Pick<Survey, 'name' | 'description' | 'numberOfProducts'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'label' | 'name' | 'required' | 'type' | 'options'>
        & { range?: Maybe<(
          { __typename?: 'Range' }
          & Pick<Range, 'min' | 'max'>
        )> }
      )> }
    ) }
  )> }
);

export type GetAddressInfoQueryVariables = Exact<{
  input: SearchAddressInput;
}>;


export type GetAddressInfoQuery = (
  { __typename?: 'Query' }
  & { searchAddress?: Maybe<(
    { __typename?: 'SearchAddressResponse' }
    & Pick<SearchAddressResponse, 'city' | 'country' | 'neighborhood' | 'number' | 'state' | 'street' | 'zipCode'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'latitude' | 'longitude'>
    )> }
  )> }
);

export type ValidateCoordinatesQueryVariables = Exact<{
  input: ValidateAddressInput;
}>;


export type ValidateCoordinatesQuery = (
  { __typename?: 'Query' }
  & { validateCoordinates: (
    { __typename?: 'ValidateCoordinatesPayload' }
    & Pick<ValidateCoordinatesPayload, 'valid'>
  ) }
);

export type CartRecommendationsQueryVariables = Exact<{
  distributionCenterId?: Maybe<Scalars['String']>;
  skusToFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CartRecommendationsQuery = (
  { __typename?: 'Query' }
  & { cartRecommendations: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'isAvailable' | 'weight' | 'id' | 'imageUrl' | 'name' | 'kind' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'classification'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ) }
  ) | (
    { __typename?: 'Meal' }
    & Pick<Meal, 'weight' | 'isAvailable' | 'id' | 'imageUrl' | 'name' | 'kind' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'classification'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ) }
  ) | (
    { __typename?: 'Product' }
    & Pick<Product, 'weight' | 'isAvailable' | 'isAgeRestricted' | 'id' | 'imageUrl' | 'name' | 'kind' | 'sku' | 'price' | 'priceFrom' | 'unit' | 'classification'>
    & { seo: (
      { __typename?: 'ProductSeo' }
      & Pick<ProductSeo, 'url'>
    ) }
  )> }
);

export type CartSyncMutationVariables = Exact<{
  input?: Maybe<CartSyncInput>;
}>;


export type CartSyncMutation = (
  { __typename?: 'Mutation' }
  & { cartSync: (
    { __typename?: 'CartSyncPayload' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
    )> }
  ) }
);

export type ValidateCouponMutationVariables = Exact<{
  input: ApplyCouponInput;
}>;


export type ValidateCouponMutation = (
  { __typename?: 'Mutation' }
  & { applyCoupon: (
    { __typename?: 'ApplyCouponPayload' }
    & Pick<ApplyCouponPayload, 'valid' | 'message' | 'finalDiscount'>
    & { coupon?: Maybe<(
      { __typename?: 'ApplyCouponCouponPayload' }
      & Pick<ApplyCouponCouponPayload, 'id' | 'name' | 'code' | 'type' | 'value' | 'isProductCoupon' | 'isFreeDeliveryCoupon'>
    )> }
  ) }
);

export type EarlyValidateCouponQueryVariables = Exact<{
  input: CouponEarlyValidationInput;
}>;


export type EarlyValidateCouponQuery = (
  { __typename?: 'Query' }
  & { earlyValidateCoupon: (
    { __typename?: 'CouponEarlyValidationPayload' }
    & Pick<CouponEarlyValidationPayload, 'valid' | 'message' | 'finalDiscount' | 'pendingValidation'>
    & { coupon?: Maybe<(
      { __typename?: 'ApplyCouponCouponPayload' }
      & Pick<ApplyCouponCouponPayload, 'id' | 'name' | 'code' | 'type' | 'value' | 'isProductCoupon' | 'isFreeDeliveryCoupon'>
    )> }
  ) }
);

export type CouponCartQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponCartQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { billing?: Maybe<(
        { __typename?: 'CartBilling' }
        & Pick<CartBilling, 'subtotal'>
      )> }
    )> }
  ) }
);

export type DeliveryAddressSaveMutationVariables = Exact<{
  input: SaveDeliveryAddressInput;
}>;


export type DeliveryAddressSaveMutation = (
  { __typename?: 'Mutation' }
  & { saveDeliveryAddress: (
    { __typename?: 'SaveDeliveryAddressPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { addresses: (
        { __typename?: 'AddressConnection' }
        & Pick<AddressConnection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'DeliveryAddress' }
          & Pick<DeliveryAddress, 'lastUsedAt' | 'street' | 'number' | 'zipCode' | 'complement' | 'neighborhood' | 'city' | 'state' | 'country'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<Coordinates, 'latitude' | 'longitude'>
          ) }
        ) | { __typename?: 'PickupAddress' }> }
      ) }
    ) }
  ) }
);

export type CheckCoordinatesQueryVariables = Exact<{
  input: ValidateAddressInput;
}>;


export type CheckCoordinatesQuery = (
  { __typename?: 'Query' }
  & { validateCoordinates: (
    { __typename?: 'ValidateCoordinatesPayload' }
    & Pick<ValidateCoordinatesPayload, 'valid'>
    & { ecommDistributionCenter?: Maybe<(
      { __typename?: 'DistributionCenter' }
      & Pick<DistributionCenter, 'id'>
    )> }
  ) }
);

export type SendSmsCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type SendSmsCodeMutation = (
  { __typename?: 'Mutation' }
  & { sendSMSCode: (
    { __typename?: 'CellphoneValidationSendCodePayload' }
    & Pick<CellphoneValidationSendCodePayload, 'success'>
  ) }
);

export type ValidateSmsCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  tokenCode: Scalars['String'];
}>;


export type ValidateSmsCodeMutation = (
  { __typename?: 'Mutation' }
  & { validateSMSCode: (
    { __typename?: 'CellphoneValidationValidateCodePayload' }
    & Pick<CellphoneValidationValidateCodePayload, 'success' | 'emails'>
  ) }
);

export type ValidateCellphoneAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type ValidateCellphoneAccountMutation = (
  { __typename?: 'Mutation' }
  & { validateCellphoneAccount: (
    { __typename?: 'CellphoneValidationValidateAccountPayload' }
    & Pick<CellphoneValidationValidateAccountPayload, 'success' | 'message'>
  ) }
);

export type ChangePasswordWithTokenMutationVariables = Exact<{
  input: ChangePasswordWithTokenInput;
}>;


export type ChangePasswordWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordWithToken: (
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'success'>
  ) }
);

export type AuthenticateWithTokenMutationVariables = Exact<{
  input: AuthenticateUserInput;
}>;


export type AuthenticateWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateUserAndResetPasswordByToken: (
    { __typename?: 'AuthenticateUserPayload' }
    & Pick<AuthenticateUserPayload, 'token' | 'refreshToken'>
  ) }
);

export type MockQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MockQueryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'version'>
);

export type DeliveryDatesQueryVariables = Exact<{
  input: AvailableDatesInput;
}>;


export type DeliveryDatesQuery = (
  { __typename?: 'Query' }
  & { availableDates: (
    { __typename?: 'SlotConnection' }
    & Pick<SlotConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Slot' }
      & Pick<Slot, 'day' | 'month' | 'weekday' | 'fullDate'>
      & { availablePeriods: Array<(
        { __typename?: 'AvailablePeriod' }
        & Pick<AvailablePeriod, 'id' | 'name' | 'timeRange'>
      )> }
    )> }
  ) }
);

import { NextPageContext } from 'next'
import { parse as parseCookie } from 'cookie'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { generateExperimentVariants, Variants_Enum } from '@lojinha/experiment'
import { IS_SERVER } from '@lojinha/helpers'
import { AppWithApolloCtx } from './apolloTypes'
import { injectExperimentsLink } from './injectExperimentsLink'
import { VitrineCustomProps } from './withApolloClient'

type ExperimentsVariants = Record<string, Variants_Enum>
type ExperimentsVariantsHeaders = Record<string, Variants_Enum>

const EXPERIMENT_COOKIES_MAX_AGE_IN_SECONDS = 180 * 24 * 60 * 60

function injectIntoAppProps(
  experiments: ExperimentsVariants,
  appProps: VitrineCustomProps
) {
  appProps.userExperimentVariants = {
    ...appProps.userExperimentVariants,
    ...experiments,
  }
}

function injectIntoApolloHeaders(
  experiments: ExperimentsVariants,
  apolloClient: ApolloClient<NormalizedCacheObject>
) {
  const headers: ExperimentsVariantsHeaders = {}
  for (const exp in experiments) {
    headers[`_experiment.${exp}`] = experiments[exp]
  }
  injectExperimentsLink(apolloClient, () => headers)
}

function injectIntoResponseCookies(
  expVariants: ExperimentsVariants,
  response: NextPageContext['res']
) {
  const cookies: string[] = []
  for (const exp in expVariants) {
    cookies.push(
      `_experiment.${exp}=${expVariants[exp]}; Path=/; Max-Age=${EXPERIMENT_COOKIES_MAX_AGE_IN_SECONDS}; SameSite=Lax`
    )
  }
  response?.setHeader('Set-Cookie', cookies)
}

export function setServerSideExperiments(
  ctx: AppWithApolloCtx,
  appProps: VitrineCustomProps
) {
  if (!IS_SERVER) {
    return
  }
  const cookies = parseCookie(ctx.req?.headers.cookie || '')
  const experimentVariants = generateExperimentVariants(cookies)
  injectIntoAppProps(experimentVariants, appProps)
  injectIntoApolloHeaders(experimentVariants, ctx.apolloClient)
  injectIntoResponseCookies(experimentVariants, ctx.res)
}

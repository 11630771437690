import { experiments } from './experiments'
import { ExperimentItem, Maybe, Variant, Variants_Enum } from './types'

function getVariantsWithScaledWeight(variants: Variant[], scale = 100) {
  const totalWeight = variants.reduce((acc, cur) => acc + cur.weight, 0)

  return variants.map(cur => {
    const originalWeight = cur.weight || 0
    return {
      ...cur,
      weight: (scale * originalWeight) / totalWeight,
    }
  })
}

export function determineVariantByWeight(
  variants: Variant[]
): Maybe<Variants_Enum> {
  const scale = 10000
  const variantsWithScaledWeight = getVariantsWithScaledWeight(variants, scale)
  if (!variantsWithScaledWeight.length) {
    return
  }

  let n = Math.floor(Math.random() * scale)

  return variantsWithScaledWeight.find(v => {
    if (v.weight >= n) {
      return true
    }
    n -= v.weight
    return false
  })?.value
}

export function getExperimentCookieName(exp: ExperimentItem): Maybe<string> {
  if (!exp?.id) {
    return
  }
  return `_experiment.${exp.id.toLowerCase()}`
}

export function stringToValidEnumValue(
  value: Maybe<string>
): Maybe<Variants_Enum> {
  if (typeof value !== 'string') {
    return null
  }
  return (
    Variants_Enum[value.toUpperCase() as keyof typeof Variants_Enum] || null
  )
}

export function generateExperimentVariants(cookies: Record<string, string>) {
  const exps = experiments.filter(e => e.setInitialValueInSSR)
  const variants: Record<string, Variants_Enum> = {}

  for (const exp of exps) {
    if (!exp?.id) {
      continue
    }
    const experimentId = exp.id.toLowerCase()
    let variantValue = cookies[
      `_experiment.${experimentId}`
    ] as Maybe<Variants_Enum>

    if (!stringToValidEnumValue(variantValue)) {
      variantValue = determineVariantByWeight(exp.variants)
    }
    if (!variantValue) {
      continue
    }
    variants[experimentId] = variantValue
  }

  return variants
}
